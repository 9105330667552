var INCORRECT_SIZE_ERROR_CODE = 501;
var INCORRECT_FILE_TYPE_ERROR_CODE = 502;
var SMALL_SIZE_ERROR_CODE = 505;
var UNKNOWN_SERVER_ERROR_CODE = 506;
var MAX_SIZE_ERROR_CODE = 507;
var NO_IMAGE_IN_FILE_ERROR_CODE = 508;
var SMALL_SCALED_SIZE_ERROR_CODE = 511;

OK.photoUpload = (function (w, d) {
    var onCloseHandlers = null;
	var tasks = {};

	function appendUploadTask(taskId, files, options) {
		var task = tasks[taskId], req = null;

		if (!files || files.length === 0) {
			return false;
		}
		if (task && !task.cntrl.isAborted() && !task.cntrl._completed && task.cntrl.uploadRequest
			&& !options.abortCurrentRequest && task.cntrl.onAppendFiles) {
			files[0].isFirst = true;
			req = task.cntrl.uploadRequest;
			//Для загрузки файлов
			req.append(files, false);
			//Для отображения процентов загрузки
			req.options.files = req.options.files.concat(files);

			task.cntrl.onAppendFiles(files);
			return true;
		}
		return false;
	}

	/**
	 *  Функция, обрабатывающая очередь загрузки.
	 */
	function addUploadTask(taskId, files, controller, options) {
	    var task = tasks[taskId], req = null;

		if (!files || files.length === 0) {
			return;
		}
		files[0].isFirst = true;
	    if (task && task.cntrl.uploadRequest && !task.cntrl._completed && !task.cntrl.isAborted()) {
	        req = task.cntrl.uploadRequest;
			if (options.abortCurrentRequest) {
				task.cntrl.onAbort && task.cntrl.onAbort();
				req.abort();
			} else {
				//Для загрузки файлов
				req.append(files, false);
				//Для отображения процентов загрузки
				req.options.files = req.options.files.concat(files);
				//Для получения нужного кол-ва токенов
				task.cntrl.filesTotal += files.length;
				return;
			}
	    }

		task = {
		    files: files,
		    cntrl: controller
		};

	    task.cntrl.attachType = options.attachType;
	    task.cntrl.fileUploadSizeLimit = options.fileUploadSizeLimit;
	    task.cntrl.unsupportedExtensionsRegexp = options.unsupportedExtensionsRegexp;

		req = FileAPI && FileAPI.upload({
			attachExif: options.attachExif,

			files: task.files,

			imageAutoOrientation: false,
			imageOriginal: false,

			filesCompleted: 0,

			chunkSize: 0,
			chunkUploadRetry: 0,

			/**
			 * Вызывает beforeUpload контроллера, устанавливаем XHR для возможности отмены
			 * @param xhr текущий XHR
			 */
			beforeupload: function (xhr) {
				task.cntrl.beforeUpload && task.cntrl.beforeUpload(xhr);
			},

			/**
			 * Подготовка параметров файл.
			 * @param file Файл для подготовки.
			 * @param opts Опции ресайза и качества
			 * @returns {boolean} Выполнилась ли предварительная подготовка
			 */
			prepare: function (file, opts) {
				FileAPI.log('Starting upload of', file.name, 'size', file.size, file.flashId ? 'with flash' : '');
				var result = FileAPI.defer();
				var _this = this;
				task.cntrl.onPrepare && task.cntrl.onPrepare(file, opts);
				FileAPI.getInfo(file, function (err, info) {
					var context = {logError: true};
					var errorCode = task.cntrl.verifyFile(file, info, context);
					FileAPI.log('Fetched info of', file.name, 'width', info && info.width, 'height', info && info.height, '; verifyFile error -', errorCode);
					if (!errorCode) {
						var fileOpts = task.cntrl.allocateId();
						fileOpts.then(function (err, allocateData) {
							if (allocateData){
								OK.util.extend(file, allocateData);
							}
							if (!err) {

								if (file.url == null) {
									FileAPI.log('Skipping', file.name);
									_this.filesCompleted++;
									task.cntrl.onProgress(_this.filesCompleted, _this.files.length, file);
					                task.cntrl.onFileComplete && task.cntrl.onFileComplete(_this.filesCompleted, _this.files.length, file);
									result.resolve(null, false);
								} else {
									opts.url = file.url + "&callback=?";

									if (task.cntrl.attachType !== 'FILE') {
										if (info && (info.width > file.transform.maxSize || info.height > file.transform.maxSize)
											&& file.type !== 'image/gif' && !(controller.losslessSupported && file.type === 'image/png')){
											FileAPI.log('Enabling tx', file.name);
											opts.imageTransform = {
												rotate: 'auto',
												type: 'image/jpeg',
												quality: file.transform.quality / 100.0,
												maxWidth: file.transform.maxSize,
												maxHeight: file.transform.maxSize
											};
										} else {
											FileAPI.log('Disabling tx', file.name);
										}
                                    }
									file.prepareTime = Date.now();
									task.cntrl.beforeFileStart && task.cntrl.beforeFileStart();
									result.resolve(null, true);
								}
							} else {
								_this.filesCompleted++;
								task.cntrl.onProgress(_this.filesCompleted, _this.files.length, file);
								task.cntrl.onFileComplete && task.cntrl.onFileComplete(_this.filesCompleted, _this.files.length, file);
								result.resolve(err, false);
							}
						}) ;
					} else {
						if (context.logError) {
							OK.photoUpload.logFileError('verify_file_error', file, errorCode);
						}
						file.response = {error: errorCode};
						_this.filesCompleted++;
						task.cntrl.onProgress(_this.filesCompleted, _this.files.length, file);
						task.cntrl.onFileComplete && task.cntrl.onFileComplete(_this.filesCompleted, _this.files.length, file);
						result.resolve(null, false);
					}

				});

				return result;
			},

			/**
			 * Действие перед загрузкой файла. Задает данные для логирования
			 * @param file Загружаемый файл
			 */
			fileupload: function (file/**Object*/, xhr/**Object*/, options/**Object*/) {
				file.startTime = Date.now();
				FileAPI.log('Starting upload', file.name);
				OK.photoUpload.logDuration("client_upl_time", file.prepareTime);
			},

			pause: function (file/**Object*/, options/**Object*/) {
				FileAPI.log('Pausing upload', file.name);
				task.cntrl.onPause && task.cntrl.onPause(file, "ioError");
			},

			fileprogress: function (evt/**Object*/, file/**Object*/, xhr/**Object*/, options/**Object*/) {
				var pr = evt.loaded/evt.total;
				task.cntrl.onProgress(this.filesCompleted, this.files.length, file, pr);
			},

			/**
			 * Действие после загрузки файла. Логирование, вызов onProgress контроллера.
			 * @param err Ошибка при загрузке
			 * @param xhr Response на загрузку фото
			 * @param file Загруженный файл
			 */
			filecomplete: function (err/**String*/, xhr/**Object*/, file/**Object*/, options/**Object*/) {
				FileAPI.log('Finishing upload', file.name, ', err:', err);
				var method = file.flashId ? "_flash" : "_html5";
				OK.photoUpload.logDuration("srv_upl_time" + method, file.startTime);

				if (!err) {
					var response;
					try {
						var txErrors = (file.__fails || {}).tx;
						if (txErrors != null) {
							FileAPI.log('TXErrors: ', txErrors.join(","));
							if (txErrors.indexOf && txErrors.indexOf('AbsolutelyBlackImg') >= 0) {
								OK.photoUpload.forceFlash(true);
								OK.logging.logger.clob('photo.upload.black', FileAPI.getTrace().join('\n'));
							}
						}

                        if (task.cntrl.attachType !== 'FILE') {
                            //евалим респонз,
                            response = JSON.parse(xhr.responseText);
                            file.response = xhr.responseText.indexOf('[') === 0 ? response[0] : response;
                            if (file.response.error) {
                            	OK.photoUpload.logDuration("srv_resp_fail" + method + "_" + file.response.error, file.startTime);
                            	if (file.response.error == INCORRECT_SIZE_ERROR_CODE ||
									file.response.error == INCORRECT_FILE_TYPE_ERROR_CODE ||
									file.response.error == UNKNOWN_SERVER_ERROR_CODE ||
									file.response.error == NO_IMAGE_IN_FILE_ERROR_CODE) {
                            		OK.photoUpload.logFail('file.response.error');
                            	}
                            } else {
                            	OK.photoUpload.logDuration("srv_resp_success" + method, file.startTime);
                            }
                        }
					} catch (e) {
						OK.photoUpload.logDuration("srv_resp_fail_eval" + method, file.startTime);
						file.response = {
							error: UNKNOWN_SERVER_ERROR_CODE,
							details: e.name + ": " + e.message
						};
						FileAPI.log("Exceptional response: ", e.name, e.message);
						OK.photoUpload.logFail('response.parse.exception');
					}
				} else {
					if (err === 'abort') {
						OK.photoUpload.logDuration("srv_resp_fail_err" + method + "_" + err + "_" + xhr.status, file.startTime);
						return;
					}
					file.response = {
						error: UNKNOWN_SERVER_ERROR_CODE,
						details: err
					};
					FileAPI.log("Illegal response: ", xhr.status, err, xhr.statusText);
					OK.photoUpload.logFail('file.complete.error');

					if (xhr.status) {
						OK.photoUpload.logDuration("srv_resp_fail_err" + method + "_" + err + "_" + xhr.status, file.startTime);
					} else if (err === 'retr_exceeded') {
						OK.photoUpload.logDuration("srv_resp_noconn" +
							"_" + (+!!FileAPI.flashEngine) + "_" + (+!!FileAPI.support.flash) +
							"_" + (FileAPI.Flash && FileAPI.Flash.readyState) +
							"_" + (FileAPI.html5 === true ? 't' : (FileAPI.html5 === false ? 'f' : 'v')), file.startTime
						);
					} else {
						OK.photoUpload.logDuration("srv_resp_fail_0" + method + "_" + err + "_" + xhr.statusText +
							"_" + FileAPI.flashEngine + "_" + FileAPI.support.flash, file.startTime);
					}
				}

				this.filesCompleted++;
				task.cntrl.onProgress(this.filesCompleted, this.files.length, file);
                task.cntrl.onFileComplete && task.cntrl.onFileComplete(this.filesCompleted, this.files.length, file);
			},

			/**
			 * Действие после завершения загрузки. Логирование, вызов onComplete контроллера
			 * @param xhr
			 */
			complete: function (err/**String*/, xhr/**Object*/) {
				req = null;
				OK.logging.logger.success("photo.upload", "batch_success");
				if (task.cntrl.setDeleter) {
				    task.cntrl.setDeleter(function () { delete tasks[taskId]; });
				} else {
				     delete tasks[taskId];
				}
				task.cntrl._completed = true;
				task.cntrl.onComplete && task.cntrl.onComplete(xhr.files);
			}
		});
        task.cntrl.uploadRequest = req;
        tasks[taskId] = task;
	}

	return {
	    controllers: {},

		sendTestRequest: function (testUrl, retryDelay, uploadRetryNum) {
			var result = FileAPI.defer(),
				testFile = FileAPI.newImage(FileAPI.EMPTY_PNG);
			testFile.name = 'testFile';
			var options = {
				url: testUrl,
				files: [testFile],
				retryDelay: retryDelay,
				filecomplete: function (err, xhr) {
					result.resolve(err && err === 'retr_exceeded' && !xhr.status);
				}
			};
			if (uploadRetryNum) {
				options.uploadRetry = uploadRetryNum;
			}
			FileAPI.upload (options);
			return result;
		},

		logFail: function(errorType) {
			OK.logging.logger.clob('photo.upload.fail', errorType + '\n' + FileAPI.getTrace().join('\n'), errorType);
		},

		logDuration: function(operation, startTime) {
			var finishTime = Date.now();
			if (finishTime > 0 && startTime > 0 && finishTime > startTime){
				OK.logging.logger.duration("photo.upload", finishTime - startTime, operation);
			} else {
                OK.photoUpload.logSuccess(operation);
			}
		},

		logSuccess: function(operation, param1) {
            OK.logging.logger.success("photo.upload", operation, param1);
		},

        logFileError: function(operation, file, errorCode) {
            OK.photoUpload.logSuccess(operation, errorCode + '_' + file.type);
        },

		/**
		 * Добавляет
		 * @param files
		 * @param controller
		 */
		addBatchToQueue: function (taskId, files, controller, options) {
			if (appendUploadTask(taskId, files, options)) {
				FileAPI.log("Appended to current upload process");
				return;
			}
			FileAPI.startTrace();
			FileAPI.log("Starting upload process");
			FileAPI.log("User agent:", w.navigator.userAgent);
			var capabilities = [];
			for (var k in FileAPI.support) {
				if (FileAPI.support.hasOwnProperty(k) && FileAPI.support[k] === true) {capabilities.push(k);}
			}
			FileAPI.log("Capabilities:", capabilities.join('|'));
			FileAPI.log("Flash:", (okFlashVersion || []).join('.'));
			var step = 0;
			try {
				controller.beforeStart && controller.beforeStart();
	            step++;
				OK.logging.logger.success("photo.upload", "batch_triggered");

				if (controller.onStart && controller.onStart(files)){
					step++;
					OK.photoUpload.queueUploadTasks(taskId, files, controller, options);
				}
			} catch (ex) {
				FileAPI.log("Exception while file selection", ex.name, ex.message, step);
				OK.photoUpload.logFail('add.batch.error');
				OK.logging.logger.success("photo.upload", "batch_error_"+step);
			}
		},

		queueUploadTasks: function (taskId, files, controller, options) {
			addUploadTask(taskId, files, controller, options);
		},

		updateView: function (taskId) {
		    var task = tasks[taskId];
		    task && task.cntrl.updateView && task.cntrl.updateView();
		},

		abortTaskById: function (taskId) {
		    var task = tasks[taskId];
		    task && task.cntrl.onAbort && task.cntrl.onAbort();
		},

		hasTask: function (taskId) {
		    return tasks[taskId] != null;
		},
		getLastUploadedId: function(taskId) {
			var task = tasks[taskId];
			return task && task.cntrl && task.cntrl.lastUploadedId;
		},
		setLastUploadedId: function(taskId, uploadedId) {
			var task = tasks[taskId];
			return task && task.cntrl && (task.cntrl.lastUploadedId = uploadedId);
		},
		setReserveIds: function(taskId){
			var task = tasks[taskId];
			return task && task.cntrl && (task.cntrl.reserveIds = true);
		},
        removeOnCloseHandler: function(handler) {
            if (onCloseHandlers && onCloseHandlers.length > 0) {
                var index = onCloseHandlers.indexOf(handler);
                if (index > -1) {
                    onCloseHandlers.splice(index, 1);
                }
            }
        },

        addOnCloseHandler: function(handler) {
            if (!onCloseHandlers) {
                onCloseHandlers = [];

                w.addEventListener('beforeunload', function (event) {
                    if (onCloseHandlers.length > 0) {
                        event.returnValue = onCloseHandlers[0].message;
                    }
                }.bind(this))
            }

            onCloseHandlers.push(handler);
        }
	}
}(window, document));


/**
 *  Абстрактный контроллер
 */
OK.photoUpload.controllers.AbstractContoller = (function(){
	return {
		abort: false,
		allocatedIds: [],

		filesTotal: 0,
		filesCompleted: 0,
        uploadRequest: null,
		videoFileSizeLimit: 3*1024*1024,
		fileUploadSizeLimit: 2147483648, // 2GB

		/**
		 * Сообщает, отменена ли загрузка
		 * @returns {boolean} Флаг о необходимости отменить загрузку
		 */
		isAborted: function (){
			return !!(this.abort);
		},

		onBlockerError: function() {
		},

		allocateId: function () {
			var result = FileAPI.defer();
			if (this.allocatedIds.length > 0) {
				result.resolve(null, this.allocatedIds.shift());
			} else {
				var _this = this,
					fileNum = Math.max(this.filesTotal - this.filesCompleted, 1);
				this.allocateIds(fileNum, {
					success: function (allocatedIds){
						_this.allocatedIds = allocatedIds;
						result.resolve(null, _this.allocatedIds.shift());
					},
					failure: function (error){
						_this.abort = true;
						_this.xhr && _this.xhr.abort();
						_this.onBlockerError(error);
						var msg;
						if (error && ~error.indexOf('upload.limit.reached')) {
							msg = error;
						} else {
							msg = 'ALLOCATE_ERROR';
							OK.photoUpload.logFail('allocate.ids.error');
						}
						result.resolve(error || true, {response: {error:msg}});
					}
				});
			}

			return result;
		},

		getErrorMessage: function (error) {
			var messageSet = this.msgs || {};
			var msg = this.msg || {};
			var errorMsg;
			switch (error){
				case 'error_505':
				case 'error.photos.min_size':
					errorMsg = messageSet.code505 || msg.smallSizeError;
					break;
				case 'error_511':
					errorMsg = messageSet.code511 || msg.smallScaledSizeError;
					break;
				case 'error_502':
					errorMsg = messageSet.code502 || msg.wrongTypeError;
					break;
				case 'TOO_MANY_PHOTOS_ERROR':
					errorMsg = msg.tooManyPhotosError;
					break;
				case 'SET_ON_MAIN_ERROR':
					errorMsg = msg.setOnMainError;
					break;
				case 'errors.event.unavailable':
					errorMsg = msg.serviceError;
					break;
				case 'BIG_IMAGE':
				case 'error.image-too-big':
					errorMsg = messageSet.bigImageError;
					break;
				case 'erorr.no.server.data':
					errorMsg = messageSet.noServerDataError;
					break;
				case 'error.image-invalid-format':
					errorMsg = messageSet.invalidFormatError;
					break;
				case 'SMALL_IMAGE':
				case 'error.image-too-small':
					errorMsg = messageSet.smallImageError;
					break;
				case 'error.too.many.files.selected':
					errorMsg = messageSet.tooManySelected;
					break;
				case 'error_501':
					errorMsg = messageSet.code501 || msg.sizeError;
					break;
				case 'error_503':
					errorMsg = messageSet.code503;
					break;
				case 'error_504':
					errorMsg = messageSet.code504;
					break;
				case 'error_506':
					errorMsg = messageSet.code506;
					break;
				case 'error_507':
					errorMsg = messageSet.code507 || msg.sizeError;
					break;
				case 'error_508':
					errorMsg = messageSet.code508;
					break;
				case 'error_509':
					errorMsg = messageSet.code509;
					break;
				case 'error_510':
					errorMsg = messageSet.code510;
					break;
				case 'errors.create-photo.photo-disabled':
					errorMsg = messageSet.createDisabled;
					break;
				case 'errors.create-photo.user-blocked':
					errorMsg = messageSet.userBlocked;
					break;
				case 'na.albums.max.upload.limit.reached':
					errorMsg = messageSet.limitReached;
					break;
				case 'READ_ERROR':
					errorMsg = messageSet.readError;
					break;
				case 'UNKNOWN':
					errorMsg = messageSet.unknownError;
					break;
				case 'WRONG_TYPE':
					errorMsg = messageSet.wrongTypeError;
					break;
				case 'UPLOAD_ERROR':
			}
			return errorMsg || msg.otherError || messageSet.otherError;
		},

		/**
		 * Проверка валидности файла
		 * @param file Файл
		 * @param info Информация о файле - размер (для изображений еще EXIF)
		 * @returns null, если все ок; код ошибки если не ок
		 */
		verifyFile: function (file, info) {
            if (this.attachType === 'FILE') {
                if (this.unsupportedExtensionsRegexp && this.unsupportedExtensionsRegexp.test(file.name)) {
                	// неверный формат
                	return INCORRECT_FILE_TYPE_ERROR_CODE;
				}

            	if (file.size > this.fileUploadSizeLimit) {
                	// неверный размер
            		return INCORRECT_SIZE_ERROR_CODE;
				}

				return null;
			}

			var isImage = /image/.test(file.type);
			if (isImage){
				if(!this.skipMinSizeCheck && ((info && (info.width < 16 || info.height < 16)) || file.size < 128)){
					return SMALL_SIZE_ERROR_CODE;
				}
				if (info && (info.width > 12000 && info.height > 12000) ){
					return MAX_SIZE_ERROR_CODE;
				}
				if (!info && file.size > 50*1024*1024 ){
					return INCORRECT_FILE_TYPE_ERROR_CODE;
				}
			} else if (/video/.test(file.type)) {
				if (file.size > this.videoFileSizeLimit) {
					return INCORRECT_SIZE_ERROR_CODE;
				}
			} else {
				if (file.size > 50*1024*1024) {
					return INCORRECT_SIZE_ERROR_CODE;
				}
			}
			return false;
		},

		/**
		 * Запрос на выделение Id для фото
		 *
		 * @param fileNum Количество файлов
		 * @param callback callback функция
		 */
		allocateIds: function (fileNum, callback) {
			var args = this.args;
			var allocateIds = args.allocateIds;
			if (typeof allocateIds === 'function') {
				return allocateIds({
					callback: callback,
					count: fileNum,
					uploadController: this
				});
			}
			var startTime = Date.now(), retryInterval = args.retryInterval || 10000;
            var attachType = this.attachType;
            OK.logging.logger.success("photo.upload", "allocate_start");
			FileAPI.log("fileNum=[" + fileNum + "]");
			$.ajax({
				type: 'POST',
				url: args.allocateAt + fileNum+"&nc="+Date.now(),
				timeout: args.timeout || 20000,
				tryCount: 0,
				retryLimit: args.retryLimit || 3,
				success: function (data, st, xhr) {
                    var i, json, error;
                    try {
                        json = JSON.parse(xhr.responseText);
                    } catch (e) {
                        error = 'allocate.parse.exception'
                    }
                    error = error || json.errorCode || json.error;
                    if (error) {
                        FileAPI.log("Allocation error", error);
                        OK.photoUpload.logDuration("allocate_err_" + error, startTime);
                        callback.failure && callback.failure(error);
                        return;
                    }

                    var options = [];
                    if (attachType == 'FILE') {
                        for (i=0; i<json.length; i++) {
                            options[i] = {
                                url: json[i].url,
                                photoId: json[i].fileId
                            }
                        }
					} else {
						if (json.tokens == null) {
							OK.photoUpload.logDuration("allocate_err_mismatch", startTime);
							FileAPI.log("Allocation token mismatch");
							callback.failure && callback.failure('token_mismatch');  // possible case if user is restricted
							return;
						}

						for (i = 0; i < json.tokens.length; i++) {
							options[i] = {
								url: json.tokens[i].url,
								photoId: json.tokens[i].id,
								transform: {
									quality: json.imageQuality,
									maxSize: json.maxImageSize
								}
							}
						}
					}

					OK.photoUpload.logDuration(this.tryCount ? "allocate_success_retry" : "allocate_success", startTime);
					FileAPI.log("Allocated", options.length, 'ids');
					callback.success(options);
                },
				error: function (xhr, st, ex) {
					FileAPI.log("Allocation try [" + this.tryCount + "/" + this.retryLimit + "] failed")
                    this.tryCount++;
                    if (this.tryCount < this.retryLimit) {
						OK.logging.logger.success("photo.upload", "allocate_start_retry");
						var ajaxOpts = this;
						setTimeout(function() {$.ajax(ajaxOpts);}, retryInterval);
                        return;
                    }
					OK.photoUpload.logDuration("allocate_fail_" + st, startTime);
					FileAPI.log("Allocation failed", st, '[', ex.name, ex.message, ']', 'ids');
					callback.failure && callback.failure(st);
                }
			});
		},

		/**
		 * Изменение статуса в процессе загрузки.
		 *
		 * @param {number} completedFiles
		 * 		Количество загруженных файлов.
		 * @param {number} totalFiles
		 * 		Общее количество загружаемых файлов.
		 * @param {object} file
		 * 		Сведения о загружаемом файле.
		 * @param {number} [progress]
		 * 		Статус прогресса загрузки файла (число от 0 до 1).
		 */
		onProgress: function (completedFiles, totalFiles, file, progress) {
			this.filesCompleted = completedFiles;
			this.filesTotal = totalFiles;
			this.emitUploadProgressEvent({
				completedFiles: completedFiles,
				totalFiles: totalFiles,
				file: file,
				progress: progress
					? progress * 100
					: progress
			});
		},

		onCreateSuccess: function(onCreateArgs) {
		},

		/**
		 * Преобразует сведения о загруженном файле в данные о фото.
		 *
		 * @param file
		 * 		Сведения о загруженном файле.
		 * @param index
		 * 		Индекс загружаемого файла.
		 * @return {object}
		 * 		Результат преобразования.
		 */
		fileToPhotoData: function(file, index) {
			var resp = file.response;
			return {
				photoId: resp.id,
				token: resp.token
			};
		},
		
		/**
		 * Действие после загрузки. Отправляет запрос на создание фото в базе
		 * @see one.app.community.dk.states.user.photo.mfu.PhotoUploadEndpoint
		 * @param files Все файлы, отправленные на загрузку.
		 */
		onComplete: function (files) {
			OK.logging.logger.success("photo.upload", "create_enter");
			if (this.isAborted() && (!this.args || !this.args.createOnAbort)) {
				OK.logging.logger.success("photo.upload", "create_aborted");
				// отмена загрузки - НЕ фатальная ошибка в коде, лога будет достаточно
				return;
			}
			this.createStartTime = Date.now();
			OK.logging.logger.success("photo.upload", "create_start");
			this.xhr = null;

			var photos = [];
			var errors = [];

			var i, file;
			for (i = 0; i < files.length; i++) {
				file = files[i];
				var resp = file.response;
				if (!resp) {
					continue;
				}
				if (!resp.error) {
					photos.push(this.fileToPhotoData(file, i));
				} else {
					errors.push({
						name: file.name,
						position: i,
						type: "error_" + resp.error
					});
				}
			}

			if (photos.length === 0 && errors.length > 0) {
				OK.photoUpload.logDuration("create_none", this.createStartTime);
				var msg = errors.length > 1 ? 'MISC_ERRORS' : errors[0].type;
				this.onBlockerError(msg);
				return;
			} else if (photos.length === 0 && this.isAborted()) {
				return;
			}

			photos.sort(function (photo0, photo1) {
				var id0 = parseInt(photo0.photoId, 16);
				var id1 = parseInt(photo1.photoId, 16);
				return id1 - id0;
			});

			if (this.skipPhotosCreate) {
				this.onCreateSuccess({
					photos: photos,
					errors: errors
				});
				return;
			}

			var saveFunc = function () {
				var url = this.args.createAt + (this.args.createAt.indexOf("?") >= 0 ? "&" : "?") + "nc=" + Date.now(),
					_this = this;

				$.ajax({
					type: "POST",
					contentType: "application/json; charset=utf-8",
					url: url,
					timeout: _this.args.timeout || 60000,
					data: JSON.stringify(photos)
				}).done(function (data, st, xhr) {
					var json;
					if (xhr.responseText) {
						try {
							json = JSON.parse(xhr.responseText);
						} catch (e) {
						}
					}
					if (json && json.success && json.success == true) {
						_this.onCreateSuccess({
							photos: photos,
							errors: errors,
							json: json
						});
					} else {
						_this.onCreateError({
							json: json
						});
					}
				}).fail(function () {
					_this.onCreateFailure();
				});
			}

			if (!this.delayPhotoCreate) {
				saveFunc.call(this);
			}
			else {
				this.onCreateSuccess({
					photos: photos,
					errors: errors,
					saveFunc: saveFunc.bind(this)
				});
			}
		},

		/**
		 * Возвращает набор переданных сообщений.
		 *
		 * @param {object} args
		 * 		Данные, передаваемые при создании контроллера.
		 * 		См. apps/projects/photo/ui/types/IPhotoUploadFacade.ts:IArgumentsUpload
		 * @return {object}
		 * 		Набор переданных сообщений или пустой объект.
		 */
		getMessageMap: function(args) {
			var message = (args && args.message) || {};
			return typeof message === 'string'
				? JSON.parse(message)
				: message;
		},

		/**
		 * Сохраняет данные, передаваемые при создании контроллера.
		 *
		 * @param {object} args
		 * 		Данные, передаваемые при создании контроллера.
		 * 		См. apps/projects/photo/ui/types/IPhotoUploadFacade.ts:IArgumentsUpload
		 * @return {object}
		 * 		Ссылка на `this`.
		 */
		saveArgs: function(args) {
			return OK.util.extend(
				this,
				{
					args: args,
					flashId: args.flashId,
					msg: this.getMessageMap(args),
					photoUploadEvent: args.photoUploadEvent,
					skipPhotosCreate: ('skipPhotosCreate' in this)
						? this.skipPhotosCreate
						: !args.createAt
				}
			);
		},

		getUploadArgs: function() {
			return this.passUploadArgs
				? this.args
				: null;
		},

		/**
		 * Генерирует событие о завершении загрузки, если у контроллера задано свойство `photoUploadEvent`.
		 *
		 * @param {object} onCreateArgs
		 * 		Объект данных, передаваемый в метод `onCreateSuccess` (cм. `onComplete`).
		 * @param {object[]} onCreateArgs.photos
		 * 		Сведения о загруженных фотографиях.
		 * @param {string} onCreateArgs.photos[].photoId
		 * 		Идентификатор загруженной фотографии.
		 * @param {Function} [onCreateArgs.saveFunc]
		 * 		Функция, используемая для сохранения фотографии.
		 */
		emitPhotoUploadEvent: function(onCreateArgs) {
			var photoUploadEvent = this.photoUploadEvent;
			if (photoUploadEvent) {
				var photos = onCreateArgs.photos;
				if (photos && photos.length) {
					var uploadArgs = this.getUploadArgs();
					require(['OK/PhotoUploaderEventBuses'], function(PhotoUploaderEventBuses) {
						if (photoUploadEvent in PhotoUploaderEventBuses) {
							var saveFunc = onCreateArgs.saveFunc;
							PhotoUploaderEventBuses[photoUploadEvent].emit(
								{
									photoList: photos.map(function(photoItem) {
										return {
											photo: photoItem,
											photoId: photoItem.photoId,
											saveFunc: saveFunc,
											uploadArgs: uploadArgs
										};
									})
								},
								this
							);
						}
					});
				}
			}
		},

		/**
		 * Генерирует указанное событие загрузки.
		 *
		 * @param eventName
		 * 		Название генерируемого события.
		 * @param {object} eventData
		 * 		Данные, передаваемые обработчикам события.
		 */
		emitUploadEvent: function(eventName, eventData) {
			if (eventName) {
				require(['OK/PhotoUploaderEventBuses'], function(PhotoUploaderEventBuses) {
					if (eventName in PhotoUploaderEventBuses) {
						PhotoUploaderEventBuses[eventName].emit(eventData, this);
					}
				});
			}
		},

		/**
		 * Генерирует событие об ошибке загрузки.
		 *
		 * @param {object | string | number} error
		 * 		Объект, представляющий данные об ошибке, передаваемые в обработчик события,
		 * 		или код ошибки.
		 * @param {object} [file]
		 * 		Сведения о загружаемом файле, которому соответствует ошибка.
		 * 		Используется только в том случае, если параметр `error` не является объектом.
		 */
		emitUploadErrorEvent: function(error, file) {
			var errorValueType = typeof error;
			var eventData;
			if (errorValueType === 'object') {
				eventData = error;
			} else {
				eventData = {
					error: error,
					errorMessage: this.getErrorMessage(
						errorValueType === 'string'
							? error
							: 'error_' + error
					)
				};
				if (file) {
					eventData.file = file;
				}
				var uploadArgs = this.getUploadArgs();
				if (uploadArgs) {
					eventData.uploadArgs = uploadArgs;
				}
			}
			this.emitUploadEvent('PHOTO_UPLOAD_ERROR', eventData);
		},
		
		/**
		 * Генерирует событие о прогрессе загрузки, если у контроллера задано свойство `uploadProgressEvent`.
		 *
		 * @param progressData
		 * 		Данные о прогрессе загрузки.
		 * @param {number} progressData.completedFiles
		 * 		Количество загруженных файлов.
		 * @param {object} progressData.file
		 * 		Сведения о загружаемом файле.
		 * @param {number | undefined} progressData.progress
		 * 		Прогресс загрузки файла (число от 0 до 100).
		 * @param {number} progressData.totalFiles
		 * 		Общее количество загружаемых файлов.
		 */
		emitUploadProgressEvent: function(progressData) {
			this.emitUploadEvent(this.uploadProgressEvent, OK.util.extend({data: this.args.data}, progressData));
		},

		/**
		 * Запрашивает data URL для указанного изображения.
		 *
		 * @param {File} file
		 * 		Данные о файле изображения.
		 * @param {object} [options]
		 * 		Настройки для обработки.
		 * @param {number} [options.height]
		 * 		Высота преобразуемого изображения.
		 * @param {string} [options.resizeStrategy='min']
		 * 		Стратегия изменения размера изображения.
		 * @param {boolean | string} [options.saveDataUrl]
		 * 		Надо ли сохранить значение data URL в объекте, переданном в параметре `file`.
		 * 		Если задано значение `true`, то значение сохраняется в поле `previewDataURL`.
		 * @param {number} [options.size=100]
		 * 		Размер изображения.
		 * @param {number} [options.width]
		 * 		Ширина преобразуемого изображения.
		 * @return {Promise}
		 */
		getImageDataUrl: function(file, options) {
			if (file && file.type.indexOf('image/') !== -1) {
				var previewSize = this.args.previewSize;
				if (!options) {
					options = {};
				}
				return new Promise(function(resolve, reject) {
					new FileAPI.Image(file)
						.resize(
							options.width || options.size || previewSize || 100,
							options.height || options.size || previewSize || 100,
							options.resizeStrategy || 'min'
						)
						.rotate('auto')
						.toData(function(err, scaled) {
							if (scaled && scaled.toDataURL) {
								var dataUrl = scaled.toDataURL();
								var saveDataUrl = options.saveDataUrl;
								if (saveDataUrl) {
									file[typeof saveDataUrl === 'string' ? saveDataUrl : 'previewDataURL'] = dataUrl;
								}
								resolve(dataUrl);
							} else {
								reject(err);
							}
						});
				});
			}
			return Promise.reject('Unsupported file type');
		},

		/**
		 * Отображает всплывающее сообщение.
		 *
		 * @param {string} msg
		 * 		Отображаемое сообщение.
		 */
		showTip: function(msg) {
			var flashId = this.flashId;
			require(['OK/NonBlockingTip'], function(NonBlockingTip) {
				if (flashId) {
					OK.photoUpload.popupAdapter.hidePopupLayer(flashId);
				}
				NonBlockingTip.create({
					fixed: true,
					mid: true,
					anim: true,
					message: msg
				});
			})
		},

		/**
		 * Отображает всплывающее сообщение об ошибке.
		 *
		 * @param {string} errorCode
		 * 		Код ошибки.
		 */
		showErrorTip: function(errorCode) {
			this.showTip(this.getErrorMessage(errorCode));
		}
		
	};
}());



/**
 * Контроллер блокирующей загрузки.
 * @see one.app.community.dk.blocks.body.layer.photo.light.FileApiUploaderLight
 * @param args Аргументы, содержащие Id для попапа, урлах для загрузки
 */
OK.photoUpload.controllers.createBlockingController = function (args){
	var controller = Object.create(OK.photoUpload.controllers.BlockingController);
	OK.util.extend(controller, {args:args, flashId: args.flashId});
	return controller;
};

OK.photoUpload.controllers.BlockingController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.BlockingController, (function() {
	return {
		/**
		 *  Действие при блокирующей ошибке
		 * @param msg Сообщение ошибки
		 */
		onBlockerError: function (msg) {
			OK.photoUpload.popupAdapter.showBlockerError(this.flashId, msg);
		},

		onPause: function (file, msg) {
            // nothing
		},

		/**
		 *  Действие при закрытии (нажатии крестика). Отменяет загрузку и убирает попап.
		 */
		onClose: function () {
			OK.logging.logger.success("photo.upload", "user_close_" + ((Date.now() - this.startTime > 10000) ? "slow" : "fast"));
			this.abort = true;
			this.xhr && this.xhr.abort();
			OK.photoUpload.popupAdapter.hidePopupLayer(this.flashId);
		},

		/**
		 * Действие при отмене (нажатии "отменить"). Отменяет загрузку и убирает попап.
		 */
		onAbort: function () {
			OK.logging.logger.success("photo.upload", "user_abort_" + ((Date.now() - this.startTime > 10000) ? "slow" : "fast"));
			this.abort = true;
			this.xhr && this.xhr.abort();
			OK.photoUpload.popupAdapter.hidePopupLayer(this.flashId);
		},

		/**
		 * Действие перед проверкой валидности файлов. Создает попап, показывает его, назначает действия на "отмену".
		 */
		beforeStart: function () {
			this.startTime = Date.now();
			OK.photoUpload.popupAdapter.showPopupLayer(this.flashId, {
				onClose: OK.fn.delegate(this.onClose, [], this),
				onAbort: OK.fn.delegate(this.onAbort, [], this)
			});
		},

		/**
		 * Действие перед allocateIds. Обновляет попап, показывая "загружено 0 из N".
		 * @param files загружаемые файлы.
		 */
		onStart: function (files) {
			this.filesTotal = files.length;
			this.filesCompleted = 0;
			OK.photoUpload.popupAdapter.updateProgressPopup(0, files.length, this.flashId);
			return true;
		},

		/**
		 * Действие после allocateIds, перед самой загузкой фото. Задает текущий XHR
		 * @param xhr Загружаемый XHR. Хранится для возможности отмены загрузки.
		 */
		beforeUpload: function (xhr) {
			this.xhr = xhr;
		},

		/**
		 * Действие в процессе загрузки. Обновляет попап.
		 * @param filesCompleted Количество загруженных файлов.
		 * @param total Общее количество загружаемых файлов.
         * @param file загружаемый файл.
		 * @param fileProgress статус прогресса загрузки файла.
		 */
		onProgress: function (filesCompleted, total, file, fileProgress) {
			this.filesCompleted = filesCompleted;
			this.filesTotal = total;
			OK.photoUpload.popupAdapter.updateProgressPopup(filesCompleted, total, this.flashId, fileProgress);
		},

		onCreateSuccess: function (onCreateArgs) {
			var index, photoId, url, startId, endId,
				json = onCreateArgs.json, photos = onCreateArgs.photos, errors = onCreateArgs.errors,
				startTime = this.createStartTime;
			for (index = 0; index < photos.length; index++){
				photoId = parseInt(photos[index].photoId, 16);
				startId = startId || photoId;
				endId = endId || photoId;
				if (startId > photoId){
					startId = photoId;
				}
				if (endId < photoId){
					endId = photoId;
				}
			}
			url = this.args.pageUrl;
			var startMatch = /st\.startIdx=(\d+)/.exec(url),
				endMatch = /st\.endIdx=(\d+)/.exec(url),
				startSeqMatch = /st\.startSeq=(\d+)/.exec(url),
				endSeqMatch = /st\.endSeq=(\d+)/.exec(url);
			if (startMatch != null) {
				var startMatchId = parseInt(startMatch[1]);
				if (startId == null || startId > startMatchId) {
					startId = startMatchId;
				}
				url = url.replace(/[\?&]?st\.startIdx=(\d+)/, '');
			}
			if (endMatch != null) {
				var endMatchId = parseInt(endMatch[1]);
				if (endId == null || endId < endMatchId) {
					endId = endMatchId;
				}
				url = url.replace(/[\?&]?st\.endIdx=(\d+)/, '');
			}
			var startSeq = json.startSeq, endSeq = json.endSeq;
			if (startSeqMatch != null) {
				var startSeqMatchId = parseInt(startSeqMatch[1]);
				if (startSeq == null || startSeq > startSeqMatchId) {
					startSeq = startSeqMatchId;
				}
				url = url.replace(/[\?&]?st\.startSeq=(\d+)/, '');
			}
			if (endSeqMatch != null) {
				var endSeqMatchId = parseInt(endSeqMatch[1]);
				if (endSeq == null || endSeq < endSeqMatchId) {
					endSeq = endSeqMatchId;
				}
				url = url.replace(/[\?&]?st\.endSeq=(\d+)/, '');
			}

			url = url.replace(/[\?&]?st\.upl=nu/, '') + "&st.upl=nu";

			if ((startId != null && endId != null) || (startSeq != null && endSeq != null)) {
				OK.photoUpload.popupAdapter.hidePopupLayer(this.flashId);
				if(startId != null && endId != null){
					url = url + "&st.startIdx=" + startId + "&st.endIdx=" + endId;
				}
				if(startSeq != null && endSeq != null){
					url = url + "&st.startSeq=" + startSeq + "&st.endSeq=" + endSeq;
				}
				if (errors.length > 0) {
					url += "&st.errors="+encodeURIComponent(JSON.stringify(errors));
				}

				OK.photoUpload.logDuration("create_success", startTime);
				navigateOnUrlFromJS(url);
			} else {
				OK.photoUpload.logDuration("create_no_bounds", startTime);
				FileAPI.log('no bounds in create');
				OK.photoUpload.logFail('null.id.error');
				OK.photoUpload.popupAdapter.showBlockerError(this.flashId, 'UPLOAD_ERROR');
			}
		},

		onCreateError: function (onCreateArgs) {
			var msg = onCreateArgs.json && onCreateArgs.json.code || 'UPLOAD_ERROR';
			OK.photoUpload.logDuration("create_err", this.createStartTime);
			OK.photoUpload.popupAdapter.showBlockerError(this.flashId, msg);
		},

		onCreateFailure: function (){
			OK.photoUpload.logDuration("create_fail", this.createStartTime);
			OK.photoUpload.popupAdapter.showBlockerError(this.flashId, 'UPLOAD_ERROR');
		}
	};
	}())
);


/**
 * Контроллер загрузки аватарки.
 * @see one.app.community.dk.blocks.body.layer.photo.light.FileApiUploaderLight
 * @param args Аргументы, содержащие Id для попапа, урлах для загрузки
 */
OK.photoUpload.controllers.createAvatarUploadController = function (args){
	var controller = Object.create(OK.photoUpload.controllers.AvatarUploadController);
	OK.util.extend(controller, {args: args, msg: JSON.parse(args.message), delayPhotoCreate: args.delayPhotoCreate === 'true'});
	OK.photoUpload.avatarUploadControllerInstance = controller;
	return controller;
};

OK.photoUpload.AvatarDialog = function () {
	return {
		onClose: function () {
			if (OK.photoUpload.avatarUploadControllerInstance) {
				OK.photoUpload.avatarUploadControllerInstance.onClose();
			} else {
				window.OK.hidePopLayerIfAny();
			}
		}
	}
};

OK.photoUpload.controllers.AvatarUploadController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.AvatarUploadController, (function() {
	return {
		showErrorPopup: function (error) {
			$("#hook_Block_AvatarDialogV2").css({display: "none"});
			$("#avatar_loading").css({"display": "none"});
			$("#avatar_confirm_div").css({"display": "none"});
			$("#avatar_error_file_name").text($("#avatar_loading_file_name").text());
			$("#avatar_error_msg").text(this.getErrorMessage(error));
			$("#modal_head").text(this.msg.errorTitle);
			$("#modal_box").css("max-height", "390px").removeClass("modal_box__change-avatar").
				addClass("modal_box__warning").addClass("modal_box__change-avatar-selected");
			$("#avatar_error").css({"display": "block"});
		},

		showLoadingPopup: function ()  {
			$("#hook_Block_AvatarDialogV2").css({display: "none"});
			$("#avatar_error").css({"display": "none"});
			$("#avatar_confirm_div").css({"display": "none"});
			$("#modal_head").text(this.msg.loadingTitle);
			$("#modal_box").removeClass("modal_box__change-avatar").removeClass("modal_box__warning")
				.css("max-height", "").addClass("modal_box__change-avatar-selected");
			$("#avatar_loading").css({"display": "block"});
		},

		showConfirmPopup: function () {
			$("#modal_head").text(this.msg.confirmTitle);
			$("#modal_box").removeClass("modal_box__change-avatar").removeClass("modal_box__warning")
				.css("max-height", "").addClass("modal_box__change-avatar-selected");
			$("#avatar_loading").css({"display": "none"});
			$("#modal_main").css({"bottom": "0"});
			$("#avatar_confirm_div").css({"display": "block"});
		},

		/** обработка ошибки при загрузке некорректого файла
		для аватарки */

		onBlockerError: function (error) {
			if (this.delayPhotoCreate) {
				this.emitUploadErrorEvent({
					errorMessage: this.getErrorMessage(error)
				});
			} else {
				this.showErrorPopup(error);
			}
		},

		onPause: function (file, msg) {
		},

		onClose: function () {
			this.abort = true;
			this.xhr && this.xhr.abort();
			window.OK.hidePopLayerIfAny();
		},

		/**
		 * Действие перед проверкой валидности файлов.
		 * Меняет модальное окошко
		 */
		beforeStart: function (firstFileName) {
            this.showLoadingPopup();
		},

		onStart: function (files) {
			if (files.length !== 1){
				this.onBlockerError("TOO_MANY_PHOTOS_ERROR");
				OK.logging.logger.success("photo.upload", "filenum_error");
				return false;
			}
			this.filesTotal = 1;
			this.filesCompleted = 0;
			return true;
		},

		beforeUpload: function (xhr) {
			this.xhr = xhr;
		},

		onProgress: function (filesCompleted, total, file, fileProgress){
			if (file && file.response && file.response.error) {
				this.onBlockerError(file.response.error);
			}
			else if (file && file.name) {
					$("#avatar_loading_file_name").text(file.name.length > 16
						? file.name.substr(0, 12) + "..."
						: file.name
					);
			}
		},

		onCreateSuccess: function (onCreateArgs) {
			if (!this.isAborted()){
				if (this.delayPhotoCreate) {
					var emitPhotoUploaded = this.emitPhotoUploaded;
					var passPhotoData = this.passPhotoData;
					var uploadArgs = this.getUploadArgs();
					require(['OK/PhotoUploaderEventBuses'], function (PhotoUploaderEventBuses) {
						if (onCreateArgs.saveFunc || emitPhotoUploaded) {
							var photos = onCreateArgs.photos;
							if (photos && photos.length === 1) {
								var data = {
									photoId: photos[0].photoId,
									saveFunc: onCreateArgs.saveFunc
								};
								if (passPhotoData) {
									data.photo = photos[0];
								}
								if (uploadArgs) {
									data.uploadArgs = uploadArgs;
								}
								PhotoUploaderEventBuses.PHOTO_UPLOADED_WITH_DELAYED_SAVE.emit(data, this);
							}
						}
						else {
							PhotoUploaderEventBuses.PHOTO_CREATED.emit({}, this);
						}
					});
				}
				else {
					navigateOnUrlFromJS(onCreateArgs.json.photoCropLink);
				}
			}
			OK.photoUpload.logDuration("create_success", this.createStartTime);
		},

        verifyFile: function(file, info, context) {
            var fileInfo = info;
            // PHOTO-4101: heic-файлы почти не поддерживаются браузерами, поэтому игнорируем проверку размера
            // и затем возвращаем ошибку о некорректном типе файла
            if (file.type === 'image/heic' && info && (!info.width || !info.height)) {
                fileInfo = null;
            }
            var superclassResult = OK.photoUpload.controllers.AbstractContoller.verifyFile.call(this, file, fileInfo);

            if(superclassResult) {
                OK.photoUpload.logFileError('verify_avatar_error', file, superclassResult);
                context.logError = false;
                return superclassResult;
            }

            if (!fileInfo) {
                OK.photoUpload.logFileError('verify_avatar_error', file, INCORRECT_FILE_TYPE_ERROR_CODE);
                context.logError = false;
                return INCORRECT_FILE_TYPE_ERROR_CODE;
            }

            return null;
        },

		
		onCreateError: function (onCreateArgs) {
			var errMsg = onCreateArgs.json && onCreateArgs.json.code || "SET_ON_MAIN_ERROR";
			if (errMsg != 'error.photos.min_size') {
				OK.photoUpload.logDuration("create_err", this.createStartTime);
			}
			this.onBlockerError(errMsg);
		},

		onCreateFailure: function () {
			OK.photoUpload.logDuration("create_fail", this.createStartTime);
			this.onBlockerError('UPLOAD_ERROR');
		}
	};
	}())
);

/**
 * Контроллер загрузки обложки.
 * @see one.app.community.dk.blocks.body.layer.photo.light.FileApiUploaderLight
 * @param args Аргументы, содержащие Id для попапа, урлах для загрузки
 */
OK.photoUpload.controllers.createCoverUploadController = function (args){
    var controller = Object.create(OK.photoUpload.controllers.CoverUploadController);
    OK.util.extend(controller, {args: args, msg: JSON.parse(args.message)});
    return controller;
};

OK.photoUpload.controllers.CoverUploadController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.CoverUploadController, (function() {

		var getMainControl = function() {
            return document.querySelector(".js-cover-install");
		};

		var getCancelControl = function() {
            return document.querySelector(".js-cover-abort");
		};

        var finalize = function() {
            OK.photoUpload.progressBar.stop(0);
            var cancelButton = getCancelControl();
            cancelButton && cancelButton.classList.add("invisible");

            var mainButton = getMainControl();
            mainButton && mainButton.classList.remove("invisible");
        };

        return {

            showError: function (error) {
                var errorContainer = document.querySelector(this.args.errorMessageSelector),
                    that = this;
                if (errorContainer) {
                    errorContainer.classList.remove("invisible");
                    errorContainer.innerHTML = that.getErrorMessage(error);
                    setTimeout(function() {
                        // нужно удостовериться, есть ли ещё такой элемент на странице
                        var container = document.querySelector(this.args.errorMessageSelector);
                        if(container) {
                            container.classList.add("invisible");
                        }
                    }.bind(this), 5000);
                } else {
                    require(['OK/NonBlockingTip'], function(NonBlockingTip) {
                        NonBlockingTip.create({
                            fixed: true,
                            mid: true,
                            anim: true,
                            message: that.getErrorMessage(error)
                        });
                    })
                }
                finalize();
            },

            onBlockerError: function (error) {
                this.showError(error);
            },

            onPause: function (file, msg) {
            },

            onClose: function () {
                this.abort = true;
                this.xhr && this.xhr.abort();
                window.OK.hidePopLayerIfAny();
            },

            onStart: function (files) {
                if (files.length !== 1){
                    this.onBlockerError("TOO_MANY_PHOTOS_ERROR");
                    OK.logging.logger.success("photo.upload", "filenum_error");
                    return false;
                }

                this.filesTotal = 1;
                this.filesCompleted = 0;
                OK.photoUpload.progressBar.start("", this.filesTotal);

                var cancelButton = getCancelControl();
                var mainButton = getMainControl();

                cancelButton && cancelButton.classList.remove("invisible");
                mainButton && mainButton.classList.add("invisible");
                return true;
            },

            beforeUpload: function (xhr) {
                this.xhr = xhr;
            },

			verifyFile: function(file, info) {
            	var superclassResult = OK.photoUpload.controllers.AbstractContoller.verifyFile.call(this, file, info);

				if(superclassResult) {
					return superclassResult;
				}

                if(/gif/.test(file.type)) {
                    return INCORRECT_FILE_TYPE_ERROR_CODE;
                }

				if (!this.skipMinSizeCheck && info) {

					if (info.width < this.args.photoMinWidth || info.height < this.args.photoMinHeight) {
						return SMALL_SIZE_ERROR_CODE;
					}

					if (this.args.scaledMaxWidth && this.args.scaledMaxHeight) {
						var scaledWidth = info.width;
						var scaledHeight = info.height;
						var scaledMaxWidth = parseInt(this.args.scaledMaxWidth, 10);
						var scaledMaxHeight = parseInt(this.args.scaledMaxHeight, 10);

						if (scaledWidth > scaledMaxWidth) {
							scaledHeight = Math.round((scaledMaxWidth * 1.0 / scaledWidth) * scaledHeight);
							scaledWidth = scaledMaxWidth;
						}
						if (scaledHeight > scaledMaxHeight) {
							scaledWidth = Math.round((scaledMaxHeight * 1.0 / scaledHeight) * scaledWidth);
							scaledHeight = scaledMaxHeight;
						}

						if (scaledWidth < this.args.photoMinWidth || scaledHeight < this.args.photoMinHeight) {
							return SMALL_SCALED_SIZE_ERROR_CODE;
						}
					}
				}

                return null;
			},

            onProgress: function (filesCompleted, total, file, fileProgress){
                if (file && file.response && file.response.error){
                    this.onBlockerError(file.response.error);
                } else {
                	// по аналогии с формой постинга - для консистентности GWT стека виджетов
                    var popLayerClose = document.getElementById("popLayer_mo");
                    if (popLayerClose) {
                        popLayerClose.click();
                    }
                    OK.photoUpload.progressBar.updateProgress(filesCompleted, total);
				}
            },

            onCreateSuccess: function (onCreateArgs) {
                if (!this.isAborted()){
                    navigateOnUrlFromJS(onCreateArgs.json.photoCropLink);
                }
                OK.photoUpload.logDuration("create_success", this.createStartTime);
                finalize();
            },

            onCreateError: function (onCreateArgs) {
                var errMsg = onCreateArgs.json && onCreateArgs.json.code || "SET_ON_MAIN_ERROR";
                if (errMsg != 'error.photos.min_size') {
                    OK.photoUpload.logDuration("create_err", this.createStartTime);
                }
                this.onBlockerError(errMsg);
                finalize();
            },

            onCreateFailure: function () {
                OK.photoUpload.logDuration("create_fail", this.createStartTime);
                this.onBlockerError('UPLOAD_ERROR');
                finalize();
            },

            onAbort: function () {
                OK.logging.logger.success("photo.upload", "user_abort_" + ((Date.now() - this.startTime > 10000) ? "slow" : "fast"));
                this.abort = true;
                this.xhr && this.xhr.abort();
                finalize();
            }
        };
    }())
);

/**
 * Контроллер загрузки для формы настройки обложки.
 * @see one.app.community.dk.blocks.body.layer.photo.light.FileApiUploaderLight
 * @param args Аргументы, содержащие Id для попапа, урлах для загрузки
 */
OK.photoUpload.controllers.createCoverSettingsUploadController = function (args){
	var controller = Object.create(OK.photoUpload.controllers.CoverSettingsUploadController);
	return controller.saveArgs(args);
};

OK.photoUpload.controllers.CoverSettingsUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.CoverSettingsUploadController, (function() {

		return {

			showError: function(msg) {
				var errorMsg = this.getErrorMessage(msg);
				var flashId = this.flashId;
				require(['OK/NonBlockingTip'], function(NonBlockingTip) {
					OK.photoUpload.popupAdapter.hidePopupLayer(flashId);
					NonBlockingTip.create({
						fixed: true,
						mid: true,
						anim: true,
						message: errorMsg
					});
				})
			},

			verifyFile: function(file, info) {
				var superclassResult = OK.photoUpload.controllers.BlockingController.verifyFile.call(this, file, info);

				if(superclassResult) {
					return superclassResult;
				}

				if(/gif/.test(file.type)) {
					return INCORRECT_FILE_TYPE_ERROR_CODE;
				}

				if (!this.skipMinSizeCheck && info) {

					if (info.width < this.args.photoMinWidth || info.height < this.args.photoMinHeight) {
						return SMALL_SIZE_ERROR_CODE;
					}

					if (this.args.scaledMaxWidth && this.args.scaledMaxHeight) {
						var scaledWidth = info.width;
						var scaledHeight = info.height;
						var scaledMaxWidth = parseInt(this.args.scaledMaxWidth, 10);
						var scaledMaxHeight = parseInt(this.args.scaledMaxHeight, 10);

						if (scaledWidth > scaledMaxWidth) {
							scaledHeight = Math.round((scaledMaxWidth * 1.0 / scaledWidth) * scaledHeight);
							scaledWidth = scaledMaxWidth;
						}
						if (scaledHeight > scaledMaxHeight) {
							scaledWidth = Math.round((scaledMaxHeight * 1.0 / scaledHeight) * scaledWidth);
							scaledHeight = scaledMaxHeight;
						}

						if (scaledWidth < this.args.photoMinWidth || scaledHeight < this.args.photoMinHeight) {
							return SMALL_SCALED_SIZE_ERROR_CODE;
						}
					}
				}

				return null;
			},

            onStart: function (files) {
                if (files.length !== 1){
                    this.onBlockerError("TOO_MANY_PHOTOS_ERROR");
                    OK.logging.logger.success("photo.upload", "filenum_error");
                    return false;
                }

                this.filesTotal = 1;
                this.filesCompleted = 0;
                OK.photoUpload.popupAdapter.updateProgressPopup(0, files.length, this.flashId);
                return true;
            },

			onCreateSuccess: function (onCreateArgs) {

				OK.photoUpload.logDuration("create_success", this.createStartTime);
				OK.photoUpload.popupAdapter.hidePopupLayer(this.flashId);

				var that = this;

				if (!that.isAborted()){
					require(['OK/utils/vanilla'], function(vanilla) {
						if (OK.Layers.getTopLayerId() === 'profile-cover-dialog') {
							OK.Layers.unregisterLast();
						}
						that.emitPhotoUploadEvent(onCreateArgs);
						vanilla.trigger(document.body, 'coverPhotoUploaderSuccess', {
							photoId: onCreateArgs.json.photoId,
							photoLink: onCreateArgs.json.previewUrl,
							photoWidth: onCreateArgs.json.photoWidth,
							photoHeight: onCreateArgs.json.photoHeight
						});
					});
				}
			},

			onBlockerError: function (msg) {
				this.showError(msg);
			},

			onCreateError: function (onCreateArgs) {
				var errMsg = onCreateArgs.json && onCreateArgs.json.code || "SET_ON_MAIN_ERROR";
				if (errMsg !== 'error.photos.min_size') {
					OK.photoUpload.logDuration("create_err", this.createStartTime);
				}
				this.showError(errMsg);
			},

			onCreateFailure: function (){
				OK.photoUpload.logDuration("create_fail", this.createStartTime);
				this.showError('UPLOAD_ERROR');
			}
		};
	}())
);

/**
 * Контроллер загрузки афиши мероприятия.
 * @see one.app.community.dk.blocks.body.layer.photo.light.FileApiUploaderLight
 * @param args Аргументы, содержащие Id для попапа, урлах для загрузки
 */
OK.photoUpload.controllers.createGroupPosterController = function (args){
	var controller = Object.create(OK.photoUpload.controllers.GroupPosterController);
	OK.util.extend(controller, {
		args: args,
		msg: controller.getMessageMap(args),
		quick: args && args.quick || false,
		layerInUse: args && args.layerInUse,
		previewUrl: "",
		LOADING_IMG: "/res/default/Images/share/Process_72x72_2.gif",
		skipMinSizeCheck: true
	});

	if (args && args.posterId) {
		controller.posterId = "#" + args.posterId + " ";
		controller.posterEl = document.getElementById(args.posterId);
	} else {
		controller.posterId = "";
	}

	if (controller.quick){
		controller.stubImg = $(controller.posterId + ".add-happening_poster_stub");
		controller.resultInput = $("#hpImageIdResult");
		controller.photoTokenInput = $("#hpImageToken");

		controller.previewImg = $(controller.posterId + ".add-happening_poster_img").hide();
		controller.previewImg = $(controller.previewImg[0]).show();

        $(".js-change-uploaded-photo").removeClass("invisible");
	} else {
		controller.stubImg = $(".js-avatar_hide, .stub-img__288");
		controller.previewImg = $(".js-avatar_show");
	}
	return controller;
};

OK.photoUpload.controllers.createGroupAvatarController = function (args){
	var CLASS_UPLOADING = '__uploading';
	var controller = Object.create(OK.photoUpload.controllers.GroupPosterController);
	var avatarEl = document.getElementById('group-avatar-' + args.posterId);
	OK.util.extend(controller, {
		avatarEl: avatarEl,
		args: args,
		msg: args && args.message && JSON.parse(args.message) || {},
		skipMinSizeCheck: true,
		elProgress: avatarEl ? avatarEl.querySelector('.js-group-avatar-progress') : null,

		beforeStart: function () {
			this.startTime = Date.now();
		},

		onStart: function (files) {
			if (files.length !== 1){
				this.onBlockerError("TOO_MANY_PHOTOS_ERROR");
				OK.logging.logger.success("photo.upload", "filenum_error");
				return false;
			}
			this.filesTotal = 1;
			this.filesCompleted = 0;
			if (this.avatarEl) {
				this.avatarEl.classList.add(CLASS_UPLOADING);
			}
			return true;
		},

		onProgress: function (filesCompleted, total, file, fileProgress) {
			if (fileProgress && this.elProgress) {
				this.elProgress.style.transform = 'translateX(' + Math.round(fileProgress * 100) + '%)';
			}
		},

		onCreateSuccess: function (onCreateArgs) {
			OK.photoUpload.logDuration("create_success", this.createStartTime);
			if (this.args.pageUrl) {
				navigateOnUrlFromJS(this.args.pageUrl);
			}
		},

		onBlockerError: function (error) {
			if (this.avatarEl) {
				this.avatarEl.classList.remove(CLASS_UPLOADING);
			}
			var message = this.getErrorMessage(error);
			require(['OK/NonBlockingTip'], function(NonBlockingTip) {
				NonBlockingTip.create({
					fixed: true,
					mid: true,
					anim: true,
					message: message,
					visibleTime: 3500
				});
			})
		}
	});

	return controller;
};

OK.photoUpload.controllers.GroupPosterController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.GroupPosterController, (function() {
	return {

        losslessSupported: true,

		onBlockerError: function (error) {
			var errorMsg = this.getErrorMessage(error);
			$(".groups_photo-upload_error").text(errorMsg).css({"display": "block"});
			if (!!this.previewUrl) {
				this.previewImg.attr('src', this.previewUrl).removeAttr('style');
				if(!this.quick) {
					this.previewImg.attr({"width":190, "height":190});
				}
			} else {
				this.previewImg.css({"display": "none"});
				this.stubImg.removeAttr('style');
			}
			this.triggerEvent('error', {
				error: error,
				errorMsg: errorMsg
			});
		},

		onPause: function (file, msg) {
		},

		onClose: function () {
			this.abort = true;
			this.xhr && this.xhr.abort();
		},

		beforeStart: function () {
			this.startTime = Date.now();
			$(this.posterId + ".groups_photo-upload_error").css({"display": "none"});
			if (this.layerInUse)
				OK.hookModel.setHookContent(this.layerInUse, "");
		},

		onStart: function (files) {
			if (files.length !== 1){
				this.onBlockerError("TOO_MANY_PHOTOS_ERROR");
				OK.logging.logger.success("photo.upload", "filenum_error");
				return false;
			}
			this.filesTotal = 1;
			this.filesCompleted = 0;
			var url = this.previewImg.attr('src');
			this.previewUrl = url && url.indexOf("image?") > -1 ? url : "";
			this.previewImg.css({"display": "inline"}).removeAttr('height').removeAttr('width')
				.attr("src", this.LOADING_IMG);
			this.stubImg.css({"display": "none"});
			this.triggerEvent('start', {
				files: files
			});
			return true;
		},
		
		triggerEvent: function(type, data) {
        	var that = this;
        	if (that.posterEl) {
        		require(['OK/utils/vanilla'], function (vanilla) {
					vanilla.trigger(that.posterEl, 'GroupPosterControllerEvent', {
						type: type,
						data: data
					});
				});
			}
		},

		beforeUpload: function (xhr) {
			this.xhr = xhr;
		},

		onProgress: function (filesCompleted, total, file, fileProgress) {
			this.triggerEvent('progress', {
				filesCompleted: filesCompleted, 
				total: total, 
				file: file, 
				fileProgress: fileProgress
			});
		},

		onCreateSuccess: function (onCreateArgs) {
			OK.photoUpload.logDuration("create_success", this.createStartTime);
			if (this.quick) {
				var photo = onCreateArgs.photos[0];
				this.previewImg.attr({'src': onCreateArgs.json.previewUrl});
				this.resultInput.attr({'value': parseInt(photo.photoId, 16)});
				if (photo.token) {
					this.photoTokenInput.attr({'value': JSON.stringify(photo.token)});
				}
			} else {
				this.args.pageUrl && navigateOnUrlFromJS(this.args.pageUrl);
			}
			this.triggerEvent('success', onCreateArgs);
		},

		onCreateError: function (onCreateArgs) {
			var errMsg = onCreateArgs.json && onCreateArgs.json.code || "create-poster-error";
			OK.photoUpload.logDuration("create_err", this.createStartTime);
			this.onBlockerError(errMsg);
		},

		onCreateFailure: function () {
			OK.photoUpload.logDuration("create_fail", this.createStartTime);
			this.onBlockerError('UPLOAD_ERROR');
		}
	};
}())
);

OK.photoUpload.controllers.createAsyncControllerV2 = function (args){
	var controller = Object.create(OK.photoUpload.controllers.AsyncControllerV2);
	OK.util.extend(controller, {args: args, flashId: args.flashId, msgs: JSON.parse(args.messages), afterUploadStates: JSON.parse(args.afterUploadStates)});
	return controller;
};

OK.photoUpload.controllers.AsyncControllerV2 = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.AsyncControllerV2, (function() {
			return {
				inProgress: false,
				lastUploadedId: null,
				canCreatePhotos: false,
				canDeleteTask: false,
				isFirstFile: true,

				/**
				 *  Действие при блокирующей ошибке
				 * @param msg Сообщение ошибки
				 */
				onBlockerError: function (msg) {
					OK.photoUpload.popupAdapter.showBlockerError(this.flashId, msg);
				},

				onPause: function (file, msg) {
				},

				isAfterUploadStateId: function (stateId) {
					var states = this.afterUploadStates, i = 0, j = states.length;
					for (; i < j; i++) {
						if (states[i] === stateId) {
							return true;
						}
					}
					return false;
				},

				/**
				 * Действие перед проверкой валидности файлов. Создает попап, показывает его, назначает действия на "отмену".
				 */
				beforeStart: function () {
					this.sendQueue = [];
					this.done = [];
					this.positions = [];
					this.startTime = Date.now();
				},

				beforeFileStart: function () {
					var url = this.updateStateUrl();
					if (url) {
						OK.photoUpload.progressBar.updateUrl(url, true);
					}
				},

				onAppendFiles: function (files) {
					this.filesTotal += files.length;
					this.appendChunk(files);
				},

				onStart: function (files) {
					if (files.length === 0) {
						return false;
					}
					this.filesTotal = files.length;
					this.successNum = 0;
					this.failedNum = 0;
					this.filesCompleted = 0;
					$('body').addClass('photo-uploading');

					var stub = document.querySelector('.photo-album_stub');
					if (stub) {
						stub.classList.toggle('invisible', true);
					}

					this.onClosingHandler = { message: this.msgs.onWindowClosingMessage };
					OK.photoUpload.addOnCloseHandler(this.onClosingHandler);
					this.appendChunk(files);

					return true;
				},

				appendChunk: function (files) {
					var i;
					var positions = [];
					for (i = 0, j = Date.now(); i < files.length; i++, j++) {
						files[i].positionId = j;
						positions.push({ id: j, name: files[i].name });
					}
					this.positions = positions.concat(this.positions);

					if (this.isAfterUploadStateId(OK.getCurrentDesktopModelId())) {
						this.createGrid(files.length);
						this.canCreatePhotos = true;
						this.canDeleteTask = true;
						$('#uploadingAbortedMsg').addClass('invisible');
						$('#uploadingCompleteMsg').addClass('invisible');
						$('#abortUploadingLink').removeClass('invisible');
					}

					var _this = this;
					this.args.stateUrl = this.args.pageUrl
							.replace(/[\?&]?st\.time=(\d+)/, '')
							.replace(/[\?&]?st\.upld=on/, '')
							+ '&st.upld=on'
							+ '&st.time=' + _this.args.time ;
					$.ajax({
						type: 'GET',
						url: '/web-api/photo/upload/getLastSeq',
						timeout: _this.args.timeout || 60000,
						data: {
							albumId: _this.args.albumId,
							groupId: _this.args.groupId,
							sharedAlbum: _this.args.sharedAlbum,
							sharedAlbumOwnerId: _this.args.sharedAlbumOwnerId
						}
					}).done(function() {
						if (!_this.isAfterUploadStateId(OK.getCurrentDesktopModelId())) {
							navigateOnUrlFromJS(_this.args.stateUrl);
						}
						if (_this.filesCompleted) {
							OK.photoUpload.progressBar.updateProgress(_this.filesCompleted, _this.filesTotal);
						} else {
							OK.photoUpload.progressBar.start(_this.args.stateUrl, _this.filesTotal);
						}
					});
				},

				onAbort: function () {
					this.abort = true;
					this.xhr && this.xhr.abort();

					// TODO: должно вызываться updateView, но из-за отсутствия четкого стейта
					// вызывать ее небезопасно, пока поштучно обновим только нужные контролы
					// this.updateView();
                    $('#abortUploadingLink').toggleClass('invisible', true);
				},

				/**
				 * Действие после allocateIds, перед самой загузкой фото. Задает текущий XHR
				 * @param xhr Загружаемый XHR. Хранится для возможности отмены загрузки.
				 */
				beforeUpload: function (xhr) {
					this.xhr = xhr;
				},

				updateProgressBar: function (id, value) {
					$('#hook_Block_' + this.args.cardBlock + id + ' .progress_bar').width(value * 100 + '%');
				},

				/**
				 * Действие в процессе загрузки. Обновляет попап.
				 * @param filesCompleted Количество загруженных файлов.
				 * @param total Общее количество загружаемых файлов.
				 */
				onProgress: function (filesCompleted, total, file, fileProgress) {
					this.filesCompleted = filesCompleted;
					this.filesTotal = total;
					if (this.isAborted()) {
						this.xhr && this.xhr.abort();
						return;
					}
					OK.photoUpload.progressBar.updateProgress(filesCompleted, total);
					if (file.response && file.response.error) {
						return;
					}
					this.createFilePreview(file.positionId, file.photoId, file);
					if (fileProgress) {
						this.updateProgressBar(file.photoId, fileProgress);
					}
				},

				createGrid: function (amount) {
					var $grid = $('.ugrid_cnt');
					if ($grid.length > 0) {
						$grid = $grid.first();
						for (var i = amount - 1; i >= 0; i--) {
							$grid.prepend(
									$('<li id="position_' + this.positions[i].id + '" class="ugrid_i __queue">'
											+ '<div>'
											+ this.createGridItem(this.positions[i].name, this.msgs.standInQueue)
											+ '</div></li>')
							);
                            var postForm = $(".js-post-after-upload-form");
                            if (postForm && postForm.length > 0) {
                                $(".js-post-topic-button").prop('disabled', true);
                                var cards = $grid.get(0).children.length;
                                if (cards > 3) {
                                    $(".js-post-uploaded-list").removeClass("__center");
                                }
                                if (cards > parseInt(postForm.attr('post-after-upload-limit'), 10)) {
                                    postForm.addClass('invisible');
                                }
                            }
						}
					}
				},

				createGridItem: function (filename, message) {
					return '<div class="photo-card">'
							+ '<span class="va"></span>'
							+ '<div class="photo-card_loading va_target">'
							+ message
							+ '<div class="bold fs-13 ellip">' + $('<div/>').text(filename).html() + '</div>'
							+ '</div>'
							+ '</div>';
				},

				createFilePreview: function (positionId, photoId, file) {
					var $item = $('#position_' + positionId);
					if ($item.length == 0) {
						return;
					}
					$('#hook_Block_UserAlbumEditBlock, #hook_Block_UserPersonalAlbumEditBlock').trigger('photoAdded');
					$('.ugrid_cnt').addClass('__uploaded');
					$item.removeClass('__queue').addClass('__loading').attr('id', 'hook_Block_PhotoCardV2Block' + photoId)
							.children().first().attr('id', 'hook_Block_' + this.args.cardBlock + photoId)
							.html('<div class="photo-card __modern __uploading ovr-menu_soh __visible">'
									+ '<div class="photo-card_cnt js-photo-card-upload">'
									+ '</div>'
									+ '<div class="disabling-layer"></div>'
									+ '<div class="ovr-menu_w">'
									+ '<div class="ovr-menu">'
									+ '<div class="progress soh-s">'
									+ '<div class="progress_bar"></div>'
									+ '</div>'
									+ '</div>'
									+ '</div>'
									+ '</div>');
					if (file && file.type.indexOf('image/') !== -1) {
						new FileAPI.Image(file)
								.resize(229, 229, 'min')
								.rotate('auto')
								.toData(function (err, scaled) {
									if (scaled && scaled.toDataURL) {
										file.previewDataURL = scaled.toDataURL();
										$item.find('.js-photo-card-upload')
										.css('background-image', 'url("' + file.previewDataURL + '")');
									}
								});
					}
				},

				activateBlock: function (element) {
					var parentHookId = OK.hookModel.getNearestBlockHookId(element);
					if (parentHookId != null) {
						OK.hookModel.captureBlockHook(parentHookId, element);
					}
				},

				showError: function (desc) {
					var msg, $item = $('#position_' + desc.positionId);
					if ($item.length == 0 && desc.photoId) {
						$item = $('#hook_Block_PhotoCardV2Block' + desc.photoId);
					}
					if ($item.length == 0) {
						return;
					}
					$item.removeClass('__queue __loading')
							.html('<div class="photo-card __upload">'
									+ '<div class="photo-card_cnt">'
                                    + '<span class="va"></span>'
                                    + '<div class="va_target">'
									+ '<div class="c-red">'
									+ '<div class="bold fs-13 ellip">' + $('<div/>').text(desc.error.fileName).html() + '</div>'
									+ this.msgs.uploadError
									+ '</div>'
									+ this.getErrorMessage(desc.error.type)
									+ '</div>'
                                    + '</div>'
									+ '</div>');
				},

				onFileComplete: function (filesCompleted, length, file) {
					OK.logging.logger.success("photo.upload", "create_enter");
					if (this.isAborted()) {
						OK.logging.logger.success("photo.upload", "create_aborted");
						return;
					}
					this.createStartTime = Date.now();
					OK.logging.logger.success("photo.upload", "create_start");
					var photo = null, error = null;
					var resp = file.response;
					if (!resp || resp.error) {
						error = {
							fileName: file.name,
							type: !resp ? "MISC_ERRORS" : "error_" + resp.error
						};
					} else {
						photo = {
							photoId: resp.id,
							token: resp.token
						};
					}
					if (error !== null) {
						this.onCreateError({
							positionId: file.positionId,
							photoId: file.photoId,
							photo: null,
							error: error
						});
						return;
					} else if (photo === null && this.isAborted()) {
						return;
					}
					this.sendQueue.push(photo);
					this.sendCreateRequest();
				},

				sendCreateRequest: function () {
					if (this.inProgress || !this.canCreatePhotos || this.sendQueue.length == 0 || this.isAborted()) {
						return;
					} else {
						this.inProgress = true;
					}
					var url = this.args.createAt + (this.args.createAt.indexOf("?") >= 0 ? "&" :"?") + "nc=" + Date.now(),
							_this = this, photo = this.sendQueue.shift(), file = this.uploadRequest.files[this.done.length];
					if (file.isFirst) {
						photo['reserveCount'] = this.filesTotal - 1;
						photo['insertBeforeId'] = null;
					} else {
						photo['reserveCount'] = 0;
						photo['insertBeforeId'] = this.lastUploadedId;
					}
					$.ajax({
						type: "POST",
						contentType: "application/json; charset=utf-8",
						url: url,
						timeout: _this.args.timeout || 60000,
						data: JSON.stringify([photo])
					}).done(function (data, st, xhr) {
						var json;
						if (xhr.responseText) {
							try {
								json = JSON.parse(xhr.responseText);
							} catch (e) {}
						}
						if (json && json.success && json.success == true) {
							_this.onCreateSuccess({
								positionId: file.positionId,
								photoId: file.photoId,
								previewDataURL: file.previewDataURL,
								photo: photo,
								error: null,
								json: json
							});
						} else {
							var error = {
								type: "error_" + (json && json.code || 'json_parse'),
								fileName: file.name
							};
							_this.onCreateError({
								positionId: file.positionId,
								photoId: file.photoId,
								photo: photo,
								error: error
							});
						}
						file.uploadComplete = true;
						_this.inProgress = false;
						_this.sendCreateRequest();
					}).fail(function () {
						var error = {
							type: "MISC_ERRORS",
							fileName: file.name
						};
						_this.callback.onCreateFailure({
							positionId: file.positionId,
							photoId: file.photoId,
							photo: photo,
							error: error
						});
						file.uploadComplete = true;
						_this.inProgress = false;
						_this.sendCreateRequest();
					});
				},

				onCreateSuccess: function (result) {
					this.done.push(result);
					this.lastUploadedId = result.photoId;
                    this.updateCounter();

					if(this.isAfterUploadStateId(OK.getCurrentDesktopModelId())){
						this.renderUploadedPhoto(result, result.photoId);

                        if (this.successNum) {
                            this.updateMsgs();
                        }
					}
					OK.photoUpload.progressBar.updateUrl(this.updateStateUrl());
					OK.photoUpload.logDuration("create_success", this.createStartTime);
				},

				updateStateUrl: function() {
					var url = this.args.stateUrl;
					if (typeof this.args.completeCode !== 'undefined') {
						url = url.replace(/[\?&]?st\.cc=(\d+)/, '');
						url = url + '&st.cc=' + this.args.completeCode;
					}
					url = url.replace(/[\?&]?st\.upl=nu/, '') + "&st.upl=nu";

					return this.args.stateUrl = url;
				},

				updateView: function () {
					this.createGrid(this.positions.length);
					this.canCreatePhotos = true;
					this.canDeleteTask = true;
					this.sendCreateRequest();
					for (var i = 0; i < this.done.length; i++) {
						if (this.done[i].error != null) {
							this.showError(this.done[i]);
						} else {
							var photoId = parseInt(this.done[i].photo.photoId, 16);
							this.renderUploadedPhoto(this.done[i], photoId);
						}
					}
					if (this.filesTotal == this.filesCompleted) {
						$('#abortUploadingLink').addClass('invisible');
						$('#uploadingCompleteMsg').removeClass('invisible');
						this.updateMsgs();
					}
					if (this.canDeleteTask) {
						this.deleter && this.deleter();
					}
				},

				renderUploadedPhoto: function(photo, photoId) {
					this.createFilePreview(photo.positionId, photoId);
					var $item = $('#hook_Block_PhotoCardV2Block' + photoId),
							cardBlock = this.args.cardBlock,
							url = '/?cmd=' + cardBlock,
							stateId = OK.getCurrentDesktopModelId();
					if (stateId) {
						url += '&st.cmd=' + stateId;
					}
                    if (this.args.groupId) {
                        url += '&st.groupId=' + this.args.groupId;
                    }
					$item.removeClass('__loading').removeAttr("id");
					this.activateBlock($item.children().first().get(0));

					var _renderUploadedPhoto = this.renderUploadedPhoto.bind(this);
					var retryNum = 0, retryMax = 10, retryDelay = 500;

					// TODO: дублирование кода, нужно перевести на requirejs и использовать OK/utils/utils
					$.ajax({
						type: 'POST',
						url: url,
						data: {
							'photoId': photoId,
							'gwt.requested': window.pageCtx.gwtHash
						},
						beforeSend: function(jqXHR, settings) {
							jqXHR.setRequestHeader('TKN', OK.tkn.get());
							jqXHR.setRequestHeader('STRD', OK.isStateRedesign());
							jqXHR.setRequestHeader('STRV', OK.getRedesignVersion());
							jqXHR.setRequestHeader(OK.CLIENT_FLAGS_HEADER, OK.getClientFlags());
							var statId = OK.getStatId();
							if (statId) {
								jqXHR.setRequestHeader(OK.STAT_ID_HEADER, statId);
							}
						}
					}).done(function(response, textStatus, xhr) {
						var token = xhr.getResponseHeader("TKN");
						if (token) {
							OK.tkn.set(token);
						}
						var redirectLocation = xhr.getResponseHeader('Redirect-Location');
						if (redirectLocation) {
							if (OK.navigation.redirect) {
								OK.navigation.redirect(redirectLocation);
							} else {
								document.location.href = decodeURIComponent(redirectLocation);
							}
							return;
						}
						var blocksContent = response.split(OK.navigation.SPLITER),
								blockIds = xhr.getResponseHeader(OK.navigation.HEADER).split(",");
						for (var i = 0; i < blocksContent.length; i++) {
							if (blockIds[i]) {
								if (photo.previewDataURL && blockIds[i] == cardBlock + photoId) {
									var content = $('<div/>').html(blocksContent[i]);
									var photoCard = content.find('#img_' + photoId);
									if (photoCard.find('img').size()) {
										photoCard.find('img').first().attr('src', photo.previewDataURL);
									} else {
										photoCard.css('background-image', 'url("' + photo.previewDataURL + '")');
									}
									blocksContent[i] = content.html();
								}
								OK.hookModel.setHookContent(blockIds[i], blocksContent[i]);
							}
						}
					}).fail(function () {
						// Блок вернул ошибку, возможно, еще не до конца были сформированы данные о фотографии, перезапросим
						if (++retryNum < retryMax) {
                            setTimeout(function () {
                            	_renderUploadedPhoto(photo, photoId);
							}, retryDelay);
						}
					});
					OK.historyManager.replaceState(this.updateStateUrl());
				},

				onCreateError: function (desc) {
					this.done.push(desc);
					OK.photoUpload.logDuration("create_err", this.createStartTime);
					this.showError(desc);
					OK.photoUpload.removeOnCloseHandler(this.onClosingHandler);
				},

				onCreateFailure: function (desc) {
					this.done.push(desc);
					OK.photoUpload.logDuration("create_fail", this.createStartTime);
					this.showError(desc);
					OK.photoUpload.removeOnCloseHandler(this.onClosingHandler);
				},

				setDeleter: function (deleter) {
					this.deleter = deleter;
				},

				onComplete: function (files) {
					this.args.stateUrl = this.args.stateUrl
							.replace(/[\?&]?st\.upld=on/, '');
					OK.historyManager.replaceState(this.args.stateUrl);
					OK.photoUpload.progressBar.updateUrl(this.args.stateUrl);

					this.xhr = null;
					OK.photoUpload.logDuration("complete_enter", this.createStartTime);
					OK.photoUpload.removeOnCloseHandler(this.onClosingHandler);
					$('body').removeClass('photo-uploading');
					this.failedNum = 0;
					var i;
					for (i = 0; i < this.done.length; i++) {
						if (this.done[i].error != null) {
							this.failedNum++;
						}
					}
					var aborted = this.isAborted();
					this.successNum = (aborted ? this.done.length : this.filesTotal) - this.failedNum;
					var isAfterUploadState = this.isAfterUploadStateId(OK.getCurrentDesktopModelId());
					if (!isAfterUploadState && !!this.successNum) {
						OK.logging.logger.success("photo.upload", "complete_away");
					}
                    if (isAfterUploadState) {

						// Показывать сообщение об успехе отсюда слишком рано
						// еще отработает sendCreateRequest, только потом появится последняя фотка
						// this.updateMsgs();

						if (aborted) {
							$('#uploadingAbortedMsg').removeClass('invisible');
							for (i = 0; i < files.length; i++) {
								if (files[i].uploadComplete) {
									continue;
								}
								var $item = $('#hook_Block_PhotoCardV2Block' + files[i].photoId);
								if ($item.length > 0) {
									$item.html(this.createGridItem(files[i].name, this.msgs.fileUploadAborted));
									continue;
								}
								$item = $('#position_' + files[i].positionId);
								if ($item.length > 0) {
									$item.html(this.createGridItem(files[i].name, this.msgs.fileUploadAborted));
								}
							}
						}
					}

					this.args.completeCode = aborted ? 1 : 0;
					OK.photoUpload.progressBar.stop(aborted ? 0 : this.args.hideTimeout);
					if (this.canDeleteTask) {
						this.deleter && this.deleter();
					}
				},

				updateMsgs: function() {
					$('#abortUploadingLink').addClass('invisible');
					if (!!this.successNum) {
						$('#uploadingCompleteMsg')
								.removeClass('invisible')
								.find('.js-show-controls')
								.text(this.msgs.uploadedPhotosNum.replace(/\{num}/i, this.successNum));
					} else {
						$('#uploadingCompleteMsg').addClass('invisible');
					}
					if (this.isAborted()) {
						$('#uploadingAbortedMsg')
								.removeClass('invisible')
								.find('.js-show-controls').text(this.msgs.fileUploadAborted);
					} else if (!!this.failedNum) {
						$('#uploadingAbortedMsg')
								.removeClass('invisible')
								.find('.js-show-controls')
								.text(this.msgs.uploadErrorsNum.replace(/\{num}/i, this.failedNum));
					} else {
						$('#uploadingAbortedMsg').addClass('invisible');
					}

					if(!this.failedNum && !this.isAborted()) {
                        require(['OK/NonBlockingTip'], function (NonBlockingTip) {
                            var allUploadedTip = NonBlockingTip.existingTips["uploadedPhotosTip"];
                            allUploadedTip && allUploadedTip.show();
                        });
                    }
				},

                updateCounter: function () {
                    if (!this._counterContainer) {
                        this._counterContainer = document.querySelector('.photo-album_counter');
                        this._counterBox = document.querySelector('.photo-album_title.__counter');
                        if (!this._counterContainer) {
                        	return;
                        }
                    }

                    var count = this.done.map(function (d) { return !d.error; }).length;

                    if (count > 0) {
                        this._counterContainer.textContent = '' + count;
                        if(this._counterBox) {
                        	this._counterBox.classList.remove('invisible');
						}
					}
                }
			};
		}())
);


OK.photoUpload.postingFormUploaderMap = {};

OK.photoUpload.controllers.createPostingFormController = function (args){
	var controller = Object.create(OK.photoUpload.controllers.PostingFormController);
	OK.util.extend(controller, {args:args, skipPhotosCreate:true, flashId: args.flashId, finishParam:args.finishParam,
		fromAttachDialog:args.fromAttachDialog});
	OK.photoUpload.postingFormUploaderMap[args.finishParam] = controller;
	return controller;
};

OK.photoUpload.controllers.PostingFormController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.PostingFormController, (function() {
	return {
		onBlockerError: function (msg) {
			window.PostingFormFileApiUploader.showBlockerError(this.finishParam, msg)
		},

		onPause: function (file, msg) {
		},

		onAbort: function () {
			OK.logging.logger.success("photo.upload", "user_abort_" + ((Date.now() - this.startTime > 10000) ? "slow" : "fast"));
			this.xhr && this.xhr.abort();
		},

		beforeStart: function () {
			this.startTime = Date.now();
			window.PostingFormFileApiUploader.initUpload(this.finishParam);
			this.closeLayer();
		},

		closeLayer: function() {
			if ("true" === this.fromAttachDialog) {
				OK.Layers.unregisterById('PhotoPickerLayer');
			}
		},

		onStart: function (files) {
			if (window.PostingFormFileApiUploader.isJSVersion) {
				window.PostingFormFileApiUploader.handleFiles(files);
				this.closeLayer();
				return false;
			}
			this.filesTotal = files.length;
			this.filesCompleted = 0;
			return true;
		},

		beforeUpload: function (xhr) {
			this.xhr = xhr;
		},

		onProgress: function (filesCompleted, total, file, fileProgress) {
			if (filesCompleted) {
				this.filesCompleted = filesCompleted;
				window.PostingFormFileApiUploader.updateProgress(this.finishParam, filesCompleted + (fileProgress || 0), total);
			}
		},

		onCreateSuccess: function (onCreateArgs) {
			var i, photos = onCreateArgs.photos, photoIds = [], tokens = "";
			for (i = 0; i < photos.length; ++i){
				var photo = photos[i];
				var photoId = parseInt(photo.photoId, 16);
				photoIds.push("" + photoId);
				tokens = tokens + photoId + "," + photo.token  + ";";
			}
			var url = this.args.pageUrl + "&type_size=";
			window.PostingFormFileApiUploader.uploadCompleted(this.finishParam, photoIds, onCreateArgs.errors, "", tokens, url, this.args.ua);
			OK.photoUpload.postingFormUploaderMap[this.finishParam] = null;
			OK.photoUpload.logDuration("create_success", this.createStartTime);
		}
	};
}())
);

OK.photoUpload.controllers.createConversationAvatarUploadController = function (args) {
	var newPicker = args.newPicker;
	var controller = Object.create(OK.photoUpload.controllers[newPicker ? 'ChatAvatarUploadController' : 'ConversationAvatarUploadController']);
	OK.util.extend(controller, {args:args, flashId: args.flashId});
	if (newPicker) {
		controller.delayPhotoCreate = args.delayPhotoCreate === 'true';
	}
	return controller;
};

OK.photoUpload.controllers.ChatAvatarUploadController = Object.create(OK.photoUpload.controllers.AvatarUploadController);
OK.util.extend(OK.photoUpload.controllers.ChatAvatarUploadController, {
	emitPhotoUploaded: true,
	passPhotoData: true,
	skipPhotosCreate: true,
	losslessSupported: true,

	onBlockerError: function (error) {
		if (this.delayPhotoCreate) {
			this.emitUploadErrorEvent(error);
		}
	},

	/**
	 * Действие перед проверкой валидности файлов.
	 */
	beforeStart: function (firstFileName) {
	}
});

OK.photoUpload.controllers.ConversationAvatarUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.ConversationAvatarUploadController, {
	skipPhotosCreate: true,
	losslessSupported: true,
	onCreateSuccess: function (onCreateArgs) {
		var flashId = this.flashId,
			photo = onCreateArgs.photos[0],
			url = '/dk?cmd=PopLayer&st.layer.cmd=ConversationAvatarDialogCrop' +
				'&st.layer.convId=' + this.args.conversationId +
				'&st.layer.photoId=' + parseInt(photo.photoId, 16) +
				'&st.layer.tkn=' + encodeURIComponent(photo.token);

		require(['OK/utils/utils'], function(utils) {
			utils.ajax({ url: url }).done(function (data, status, xhr) {
				utils.updateBlockModelCallback(data, status, xhr);
				OK.photoUpload.popupAdapter.hidePopupLayer(flashId);
			});
		});
	}
});

OK.photoUpload.controllers.createPostingFormLinkImageUploadController = function (args) {
	var controller = Object.create(OK.photoUpload.controllers.PostingFormLinkImageUploadController);
	OK.util.extend(controller, {args:args, flashId: args.flashId});
	return controller;
};

OK.photoUpload.controllers.PostingFormLinkImageUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.PostingFormLinkImageUploadController, {
	skipPhotosCreate: true,
	losslessSupported: true,
	onCreateSuccess: function (onCreateArgs) {
		var flashId = this.flashId;
		var photo = onCreateArgs.photos[0];
		var url = this.args.linkUploadedImageCropUrl.replace(/&amp;/g, '&') + encodeURIComponent(photo.token);

		require(['OK/utils/utils'], function(utils) {
			utils.ajax({ url: url }).done(function (data, status, xhr) {
				OK.photoUpload.popupAdapter.hidePopupLayer(flashId);
				utils.updateBlockModelCallback(data, status, xhr);
			});
		});
	}
});


/**
 * Контроллер загрузки для приложения Memories.
 *
 * @param args
 * 		Аргументы, влияющие на работу контроллера.
 * 		См. apps/projects/photo/ui/types/IPhotoUploadFacade.ts:IArgumentsUpload
 */
OK.photoUpload.controllers.createMemoriesAppUploadController = function(args) {
	var controller = Object.create(OK.photoUpload.controllers.MemoriesAppUploadController);
	return controller.saveArgs(args);
};

OK.photoUpload.controllers.MemoriesAppUploadController = Object.create(OK.photoUpload.controllers.AbstractContoller);
OK.util.extend(OK.photoUpload.controllers.MemoriesAppUploadController, {
	passUploadArgs: true,
	uploadProgressEvent: 'UPLOAD_PROGRESS',
	
	onBlockerError: function (errorCode) {
		this.showErrorTip(errorCode);
	},

	/**
	 * Действие перед непосредственным началом загрузки файлов.
	 *
	 * @param {object[]} files
	 * 		Сведения о загружаемых файлах.
	 * @return
	 * 		`true`, если надо начать загрузку.
	 */
	onStart: function (files) {
		var that = this;
		var dataUrlPromiseList = [];
		var time = Date.now();
		this.filesTotal = files.length;
		this.filesCompleted = 0;
		files.forEach(function(fileItem, index) {
			fileItem.startId = time + '-' + index;
			dataUrlPromiseList.push(
				this.getImageDataUrl(fileItem, {saveDataUrl: true})
					.catch(function() {
						// Надо сгенерировать событие, даже если не удалось получить data URL
					})
			);
		}, this);
		
		Promise.all(dataUrlPromiseList)
			.then(function() {
				that.emitUploadEvent(
					'PHOTO_LIST_UPLOAD_START',
					{
						data: that.args.data,
						fileList: files
					}
				);
			});
		
		return true;
	},

	verifyFile: function(file, info) {
		var result = OK.photoUpload.controllers.AbstractContoller.verifyFile.call(this, file, info);

		if (!result && !info) {
			result = INCORRECT_FILE_TYPE_ERROR_CODE;
		}

		return result;
	},

	/**
	 * Обрабатывает завершение загрузки файла.
	 *
	 * @param {number} completedFiles
	 * 		Число загруженных файлов.
	 * @param {number} totalFiles
	 * 		Общее число загружаемых файлов.
	 * @param {object} file
	 * 		Сведения о загруженном файле.
	 */
	onFileComplete: function (completedFiles, totalFiles, file) {
		var response = file.response || {};
		var error = response.error;
		if (error) {
			this.emitUploadErrorEvent(error, file);
		}
	},

	/**
	 * Преобразует сведения о загруженном файле в данные о фото.
	 *
	 * @param file
	 * 		Сведения о загруженном файле.
	 * @param index
	 * 		Индекс загружаемого файла.
	 * @return {object}
	 * 		Результат преобразования.
	 */
	fileToPhotoData: function(file, index) {
		var result = OK.photoUpload.controllers.AbstractContoller.fileToPhotoData.call(this, file, index);
		result.startId = file.startId;
		return result;
	},

	onCreateSuccess: function (onCreateArgs) {
		if (!this.isAborted()) {
			this.emitPhotoUploadEvent(onCreateArgs);
		}
	}
});


OK.photoUpload.controllers.createMotivatorImageUploadController = function (args) {
	var controller = Object.create(OK.photoUpload.controllers.MotivatorImageUploadController);
	OK.util.extend(controller, {args:args, flashId: args.flashId});
	return controller;
};

OK.photoUpload.controllers.MotivatorImageUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.MotivatorImageUploadController, {
	skipPhotosCreate: true,
	losslessSupported: true,
	onCreateSuccess: function (onCreateArgs) {
		var flashId = this.flashId;
		var photo = onCreateArgs.photos[0];
		var postingFormUrl = this.args.postingFormUrl.replace(/&amp;/g, '&') + encodeURIComponent(photo.token);

		OK.photoUpload.popupAdapter.hidePopupLayer(flashId);
        if (this.args.insideImageSelectLayer) {
            // скрыть леер выбора картинки
            document.getElementById("popLayer_mo").click();
        }
        if (!this.isAborted()) {
            navigateOnUrlFromJS(postingFormUrl);
        }
	}
});

OK.photoUpload.controllers.createFriendCoverSuggestImageUploadController = function (args) {
	var controller = Object.create(OK.photoUpload.controllers.FriendCoverSuggestImageUploadController);
	OK.util.extend(controller, {args:args, flashId: args.flashId, msg: JSON.parse(args.message)});
	return controller;
};

OK.photoUpload.controllers.FriendCoverSuggestImageUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.FriendCoverSuggestImageUploadController, {
	skipPhotosCreate: true,
	losslessSupported: true,
	onCreateSuccess: function (onCreateArgs) {
		require(['OK/PhotoUploaderEventBuses'], function (PhotoUploaderEventBuses) {
			PhotoUploaderEventBuses.PHOTO_UPLOADED.emit({
				token: onCreateArgs.photos[0].token
			})
		}, this);
	},
	onProgress: function (filesCompleted, total, file, fileProgress){
		 if (file && file.response && file.response.error) {
			 this.onBlockerError(file.response.error);
		 }
	},
	beforeStart: function() {
		// nothing
	},
	showError: function (error) {
		this.emitUploadErrorEvent({
			errorMessage: this.getErrorMessage(error)
		});
	},
	onBlockerError: function (error) {
		this.showError(error);
	},
	verifyFile: function(file, info) {
		var superclassResult = OK.photoUpload.controllers.AbstractContoller.verifyFile.call(this, file, info);

		if(superclassResult) {
			return superclassResult;
		}

		if(/gif/.test(file.type)) {
			return INCORRECT_FILE_TYPE_ERROR_CODE;
		}

		if (!info) {
			return INCORRECT_FILE_TYPE_ERROR_CODE;
		}

		if (!this.skipMinSizeCheck && info) {

			if (info.width < this.args.photoMinWidth || info.height < this.args.photoMinHeight) {
				return SMALL_SIZE_ERROR_CODE;
			}

			if (this.args.scaledMaxWidth && this.args.scaledMaxHeight) {
				var scaledWidth = info.width;
				var scaledHeight = info.height;
				var scaledMaxWidth = parseInt(this.args.scaledMaxWidth, 10);
				var scaledMaxHeight = parseInt(this.args.scaledMaxHeight, 10);

				if (scaledWidth > scaledMaxWidth) {
					scaledHeight = Math.round((scaledMaxWidth * 1.0 / scaledWidth) * scaledHeight);
					scaledWidth = scaledMaxWidth;
				}
				if (scaledHeight > scaledMaxHeight) {
					scaledWidth = Math.round((scaledMaxHeight * 1.0 / scaledHeight) * scaledWidth);
					scaledHeight = scaledMaxHeight;
				}

				if (scaledWidth < this.args.photoMinWidth || scaledHeight < this.args.photoMinHeight) {
					return SMALL_SCALED_SIZE_ERROR_CODE;
				}
			}
		}

		return null;
	},
});

OK.photoUpload.controllers.createVideoCoverUploadController = function (args) {
	var controller = Object.create(OK.photoUpload.controllers.VideoCoverUploadController);
	OK.util.extend(controller, {args: args, flashId: args.flashId});
	return controller;
};

OK.photoUpload.controllers.VideoCoverUploadController = Object.create(OK.photoUpload.controllers.ChatAvatarUploadController);
OK.util.extend(OK.photoUpload.controllers.VideoCoverUploadController, {
	delayPhotoCreate: true,
	emitPhotoUploaded: true,
	passPhotoData: true,
	passUploadArgs: true,
	skipPhotosCreate: true,
	losslessSupported: true
});

OK.photoUpload.controllers.createDailyPhotoChallengeBgUploadController = function (args){
	return Object.create(OK.photoUpload.controllers.DailyPhotoChallengeBgUploadController).saveArgs(args);
};

OK.photoUpload.controllers.DailyPhotoChallengeBgUploadController = Object.create(OK.photoUpload.controllers.BlockingController);
OK.util.extend(OK.photoUpload.controllers.DailyPhotoChallengeBgUploadController, {
	losslessSupported: true,

	onPrepare: function(file) {
		var that = this;
		this.getImageDataUrl(file, {
			saveDataUrl: true
		}).then(function() {
			that.emitUploadEvent(
				'PHOTO_UPLOAD_START',
				{
					data: that.args.data,
					file: file
				}
			);
		});
	},

	onFileComplete: function (completedFiles, totalFiles, file) {
		var response = file.response || {};
		var data = {
			data: this.args.data,
			file: file,
			token: response.token || ''
		};
		if (response.error) {
			data.error = {
				errorMessage: String(response.error)
			};
		}
		this.emitUploadEvent('PHOTO_UPLOADED', data);
	},

	onCreateSuccess: function (onCreateArgs) {
		OK.photoUpload.popupAdapter.hidePopupLayer(this.flashId);
		if (!this.isAborted()) {
			this.emitPhotoUploadEvent(onCreateArgs);
		}
		OK.photoUpload.logSuccess('daily_photo_challenge_bg_success');
	}
});

OK.photoUpload.popupAdapter = (function (w,d){
	return {

		callbackClose: function (flashId) {
			OK.photoUpload.popupAdapter.callbacks && OK.photoUpload.popupAdapter.callbacks.onClose();
		},

		callbackAbort: function (flashId) {
			OK.photoUpload.popupAdapter.callbacks && OK.photoUpload.popupAdapter.callbacks.onAbort();
		},

		showPopupLayer: function (flashId, callbacks) {
			w.__OMFU__showPopupLayer(flashId);
			OK.photoUpload.popupAdapter.callbacks = callbacks;
		},

		showBlockerError: function (flashId, msg) {
			w.__OMFU__showStopperError(msg, "", "", flashId);
		},

		updateProgressPopup: function(filesCompleted, total, flashId, fileProgress) {
			fileProgress = fileProgress || 0;
			if (total - filesCompleted <= 1){
				filesCompleted = total;
			}
			w.__OMFU__updateProgressPopup(Math.min((filesCompleted + fileProgress) || 0.1, total), total, flashId);
		},

		hidePopupLayer: function (flashId) {
			OK.photoUpload.popupAdapter.callbacks = null;
			w.__OMFU__hidePopupLayer(flashId);
		}

	}
}) (window, document);

OK.photoUpload.progressBar = (function() {

    var finished;
    var incompleteStateUrl;
    var completeStateUrl;

    function updateProgress(filesCompleted, totalFiles) {
        var currentProgress = Math.round(filesCompleted * 100.0 / totalFiles);
        $('#progress_bar').css('width', currentProgress + '%');
        $('#progress').attr('aria-valuenow', currentProgress);
        setFilesCount(filesCompleted, totalFiles);
    }

    function start(url, totalFiles) {
		finished = false;
		incompleteStateUrl = url;
		setHrefs();
		$('#uploadPageLink').click(hide);
		$('#myPhotosLink').click(hide);
		setFilesCount(0, totalFiles);
		var progress = $('#progress');
		progress.removeClass('__collapse');
		progress.removeClass('__complete');
		updateProgress(0, totalFiles);
	}

    function setHrefs(useCompleted) {
        var url = (useCompleted || finished) ? completeStateUrl : incompleteStateUrl;
        $('#uploadPageLink').attr('href', url + "&st._aid=PhotoUploadBar_UploadPage");
        $('#myPhotosLink').attr('href', url + "&st._aid=PhotoUploadBar_MyPhotos");
    }

    function setFilesCount(uploaded, total) {
        $('#uploadedFiles').text(uploaded);
        $('#totalFiles').text(total);
    }

    function hide() {
        if (finished) {
            $('#progress').addClass('__collapse');
        }
    }

    function stop(timeout) {
        $('#progress').addClass('__complete');
        finished = true;
        setHrefs();
        setTimeout(function() {
            hide();
        }, timeout);
    }

    function updateUrl(url, setUrl) {
        completeStateUrl = url;
        if (finished || setUrl) {
            setHrefs(setUrl);
        }
    }

    return {
        updateProgress: updateProgress,
        start: start,
        stop: stop,
        updateUrl: updateUrl
    };
})();
